import React from "react";
import { Select } from "@amzn/awsui-components-react";
import "./navbar.css";

const sites = [
    {
        id: "/",
        label: "Secure AI Sandbox",
    },
    {
        id: "https://trms-data-analytics-na.amazon.com/workflows",
        label: "CMLS Analytics",
    },
    { id: "https://unified-ml-catalog.ctps.amazon.dev/", label: "Unified Machine Learning Catalog" },
];

const redirect = (url) => {
    window.location.href = url;
};

function NavBar() {
    return (
        <div id="navbar">
            <ul id="menu-list">
                <li id="title">CMLS</li>
                <li id="ml-auto"></li>
                <li id="links">
                    <Select
                        options={sites}
                        selectedOption={sites[0]}
                        onChange={(event) => redirect(event.detail.selectedOption.id)}
                    />
                </li>
            </ul>
        </div>
    );
}

export default NavBar;
