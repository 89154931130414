import React from "react";

import { AppLayout, Button, Flashbar, Textarea, Form, FormField, Input, Select, SegmentedControl, Alert } from "@amzn/awsui-components-react";
import { Redirect } from "react-router-dom";
import { Header, SpaceBetween, Container, ContentLayout } from "@amzn/awsui-components-react-v3";
import SideBar from "../../navigation/sidebar.js";

import { verify, getOptions, getApiUrl } from "../../auth/login.js";


import "./create.css";
import { PLATFORM_IDS } from "../../constants/constants";
import {
    enforceSandboxIdleCpuUtilInput,
    enforceSandboxIdleTimeInput,
    enforceSandboxStorageInput,
} from "../../util/form-utils";
import AppContext from "../../context/AppContext";
import { getSidebarItemFromPageStage } from "../home/home"

export interface ScheduleConfigProps {
    loading: boolean;
    setLoading: any;
    createSchedule: any;
}

export interface ScheduleConfigState {
    scheduleName: string;
    gitfarmPackage: string;
    templateName: string;
    templateVersion: string;
    cronExpression: string,
    executionStructure: string,
    inputExecutionDocument: Object,
}

class ScheduleConfig extends React.Component<ScheduleConfigProps, ScheduleConfigState> {
    state = {
        scheduleName: "",
        gitfarmPackage: "",
        templateName: "",
        templateVersion: "",
        cronExpression: "",
        executionStructure: "",
        inputExecutionDocument: {},
    };


    constructor(props: ScheduleConfigProps) {
        super(props);
        this.create = this.create.bind(this);
        this.setInputExecutionDocument = this.setInputExecutionDocument.bind(this);
    }

    create() {
        const newConfig = {
            scheduleName: this.state.scheduleName,
            gitfarmPackage: this.state.gitfarmPackage,
            templateName: this.state.templateName,
            templateVersion: this.state.templateVersion,
            cronExpression: this.state.cronExpression,
            inputExecutionDocument: this.state.inputExecutionDocument,
        };
        this.props.setLoading();
        this.props.createSchedule(newConfig);
    }

    setInputExecutionDocument(value) {
        this.setState({
            inputExecutionDocument: JSON.parse(value)
        })
    }




    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div className="awsui-util-container col-l-8 col-xl-7 col-m-9 col-s-12 col-xs-12 col-xxs-12">
                <div>

                    <SpaceBetween size="l">
                        <Container header={<Header variant="h2">Schedule Information</Header>}>
                            <div className="sandbox-form-text">
                                <FormField label="Schedule Name" description="Provide the name of your schedule.">
                                    <Input

                                        onChange={({ detail }) => {
                                            this.setState({ scheduleName: detail.value })
                                        }}
                                        value={this.state.scheduleName}
                                    />
                                </FormField>
                            </div>
                            <div className="sandbox-form-text">
                                <FormField label="Cron Expression"
                                    description={
                                        <>
                                            Indicate the frequency of your schedule in cron expressions. All scheduled events use UTC+0 time zone by default.
                                            Check the link for the AWS rule cron expression format.
                                            
                                            <a href="https://docs.aws.amazon.com/scheduler/latest/UserGuide/schedule-types.html#cron-based"
                                                target="_blank"> Learn More</a>
                                            <br />
                                        </>
                                    }
                                >
                                    <Input
                                        onChange={({ detail }) => {
                                            this.setState({ cronExpression: detail.value })
                                        }}
                                        value={this.state.cronExpression}
                                    />
                                </FormField>
                            </div>



                        </Container>
                        <Container header={<Header variant="h2">Template Information</Header>}>
                            <div className="sandbox-form-text">
                                <FormField label="Gitfarm Package"
                                    description="Provide the Gitfarm package name of your chosen template.">
                                    <Input

                                        onChange={({ detail }) => {
                                            this.setState({ gitfarmPackage: detail.value })
                                        }}
                                        value={this.state.gitfarmPackage}
                                    />
                                </FormField>
                            </div>
                            <div className="sandbox-form-text">
                                <FormField label="Template Name"
                                    description="Provide the name of your chosen template." >
                                    <Input

                                        onChange={({ detail }) => {
                                            this.setState({ templateName: detail.value })
                                        }}
                                        value={this.state.templateName}
                                    />
                                </FormField>
                            </div>
                            <div className="sandbox-form-text">
                                <FormField label="Template Version"
                                    description="Provide the version of your chosen template." >
                                    <Input

                                        onChange={({ detail }) => {
                                            this.setState({ templateVersion: detail.value })
                                        }}
                                        value={this.state.templateVersion}
                                    />
                                </FormField>
                            </div>
                        </Container>
                        <Container header={<Header variant="h2">Input Execution Document</Header>}>
                            <Textarea
                                onChange={({ detail }) => {
                                    this.setInputExecutionDocument(detail.value)
                                }}
                                readOnly
                            />
                        </Container>

                    </SpaceBetween>

                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <Button
                                id="update-button"
                                variant="primary"
                                disabled={false}
                                loading={this.props.loading}
                                onClick={this.create}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface CreateFormProps { }

interface CreateFormState {
    loading: boolean;
    showAlert: boolean;
    alert: string;
    showSuccess: boolean;
    success: string;
}

class CreateForm extends React.Component<CreateFormProps, CreateFormState> {
    static contextType = AppContext;

    state = {
        loading: false,
        showAlert: false,
        alert: "",
        showSuccess: false,
        success: "",
    };

    constructor(props: CreateFormProps) {
        super(props);

        this.createSchedule = this.createSchedule.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    createSchedule(newConfig: any) {
        console.log(`Creating schedule with parameters ${JSON.stringify(newConfig)}`);
        if (newConfig === undefined) {
            this.showAlert("There was an error with the configuration. Please try again with a valid configuration.");
            this.setState({ loading: false });
            return;
        } else if (newConfig.scheduleName.length==0) {
            this.showAlert("There was an error with the schedule name of the schedule.");
            this.setState({ loading: false });
            return;
        } else if (newConfig.cronExpression.length==0) {
            // TODO: Validate cron expression
            this.showAlert("There was an error with the cron expression of the schedule.");
            this.setState({ loading: false });
            return;
        } else if (newConfig.gitfarmPackage.length==0) {
            this.showAlert("There was an error with the gitfarm package name of the schedule.");
            this.setState({ loading: false });
            return;
        } else if (newConfig.templateName.length==0) {
            this.showAlert("There was an error with the template name of the schedule.");
            this.setState({ loading: false });
            return;
        } else if (newConfig.templateVersion.length==0) {
            this.showAlert("There was an error with the template version of the schedule.");
            this.setState({ loading: false });
            return;
        }
        const inputExecutionDocument = newConfig.inputExecutionDocument
        const scheduleName = newConfig.scheduleName + localStorage.getItem("modsAccount")
        const cronExpression = newConfig.cronExpression
        const account = localStorage.getItem("modsAccount")
        const user = localStorage.getItem("userId");

        const gitfarmPackageName = newConfig.gitfarmPackage
        const templateName = newConfig.templateName
        const templateVersion = newConfig.templateVersion
        const executionDocumentName = account + user
        const executionAwsAccountId = account

        this.context.modsWorkflowManagementClient.requestConcreteExecutionDocumentUploadLink(
            gitfarmPackageName, templateName, templateVersion, executionDocumentName)
            .then((res) => {

                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                }
                return res;
            })
            .then(async (data) => {
                const presignedUrl = data.data.uploadPresignedUrl
                console.log(presignedUrl)
                console.debug("Now trying to fetch presignedUrl:", presignedUrl)
                if(presignedUrl===undefined){
                    this.showAlert("Failed to fetch uploadPresignedUrl");
                    this.setState({ loading: false });
                    return;
                }
                await fetch(presignedUrl, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputExecutionDocument)
                })
                    .then((res) => {
                        if (res.status === 200) {
                            this.showSuccess("Execution document uploaded.")
                        }else{
                            this.showAlert
                        }
                    }).catch(this.showAlert)

                this.context.modsWorkflowManagementClient.createSchedule(
                    scheduleName, cronExpression, "testStartDate", gitfarmPackageName, templateName, templateVersion, executionDocumentName, data.data.executionDocumentVersion, executionAwsAccountId, user
                ).then((res) => {

                    if (res.status === 200) {
                        this.showSuccess("Your schedule is being created.");
                        this.setState({ loading: false });
                    } else{
                        if(res.data.message===undefined){
                            this.showAlert("There was an error with creating your schedule. Please try again later.");
                        }else{
                            this.showAlert(res.data.message);
                        }
                        this.setState({ loading: false });
                    } 
                })
                    .catch(this.showAlert);
            }).catch(this.showAlert)
    }

    showAlert(message: string) {
        this.setState({
            showAlert: true,
            alert: message,
            showSuccess: false,
            success: "",
        });
    }

    showSuccess(message: string) {
        this.setState({
            showSuccess: true,
            success: message,
            showAlert: false,
            alert: "",
        });
    }

    setLoading() {
        this.setState({
            loading: true,
        });
    }

    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    dismiss: () =>
                                        this.setState({
                                            showAlert: false,
                                            alert: "",
                                            showSuccess: false,
                                            success: "",
                                        }),
                                },
                            ]}
                        />
                    </div>
                ) : null}

                <Form>
                    <ScheduleConfig
                        loading={this.state.loading}
                        setLoading={this.setLoading}
                        createSchedule={this.createSchedule}
                    />
                </Form>
            </div>
        );
    }
}

const Content = () => (
    <div id="Create">
        <h1>Create Schedule</h1>
        <CreateForm />
    </div>
);

class CreateSchedule extends React.Component<any, any> {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                content={<Content />}
                navigation={<SideBar activeHref="/create_schedules" items={getSidebarItemFromPageStage(this.context.pageStage)} />}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default CreateSchedule;
