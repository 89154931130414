import React from "react";
import { Icon, Popover } from "@amzn/awsui-components-react";
import { WORKFLOW_STATUS, stringToWorkflowStatus } from "../../../constants/constants";

function getStatusClass(status: WORKFLOW_STATUS): string {
    const statusPrefix = "awsui-util-status-";
    let statusSuffix;
    switch (status) {
        case WORKFLOW_STATUS.ACTIVE:
            statusSuffix = "positive";
            break;
        case WORKFLOW_STATUS.DEPRECATED:
            statusSuffix = "negative";
            break;
        default:
            statusSuffix = "info";
            break;
    }

    return statusPrefix + statusSuffix;
}

function getStatusIconName(status: WORKFLOW_STATUS): any {
    const iconPrefix = "status-";
    let iconSuffix;
    switch (status) {
        case WORKFLOW_STATUS.ACTIVE:
            iconSuffix = "positive";
            break;
        case WORKFLOW_STATUS.DEPRECATED:
            iconSuffix = "info";
            break;
        default:
            iconSuffix = "in-progress";
    }

    return iconPrefix + iconSuffix;
}

export const COLUMN_DEFINITIONS = [
    {
        id: "templateName",
        header: () => "Template Name",
        cell: (item: any) => (
            <div>
                <a href={`/workflows/${item.gitfarmPackage}/${item.templateName}/${item.templateVersion}`}>{item.templateName}</a>
            </div>
        ),
        allowLineWrap: true,
    },
    {
        id: "templateVersion",
        header: () => "Version",
        cell: (item: any) => item.templateVersion,
        allowLineWrap: true,
    },
    {
        id: "templateStatus",
        cell: (item: any) => {
            const status = stringToWorkflowStatus(item.templateStatus);
            const statusLabel = (
                <div className={getStatusClass(status)}>
                    <Icon name={getStatusIconName(status)} />
                    <span>{` ${item.templateStatus}`}</span>
                </div>
            );
            return statusLabel;
        },
        header: () => "Status",
        allowLineWrap: true,
    },
    {
        id: "createDate",
        header: () => "Create Time",
        cell: (item: any) => item.createDate,
        allowLineWrap: true,
    },
    {
        id: "templateAuthor",
        header: () => "Author",
        cell: (item: any) => item.templateAuthor,
        allowLineWrap: true,
    },
    {
        id: "templateDescription",
        header: () => "Description",
        cell: (item: any) => item.templateDescription,
        minWidth: "200px",
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS = [
    { id: "templateName", field: "Template Name" },
    { id: "templateVersion", field: "Version" },
    { id: "templateStatus", field: "Status" },
    { id: "createDate", field: "Create Time" },
    { id: "templateAuthor", field: "Author" },
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: "Main workflow properties",
        options: [
            { id: "templateName", label: "Template name", editable: false, visible: true },
            { id: "templateVersion", label: "Version", editable: false, visible: true },
            { id: "templateStatus", label: "Status", editable: true, visible: true },
            { id: "createDate", label: "Create Time", editable: true, visible: true },
            { id: "templateAuthor", label: "Author", editable: true, visible: true },
            { id: "templateDescription", label: "Description", editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 5, label: "5 Workflows" },
    { value: 10, label: "10 Workflows" },
    { value: 15, label: "15 Workflows" },
];



