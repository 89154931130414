// This is the web application stage
export enum PageStage {
    SETTINGS_LOADING_REQUIRED, // Loading endpoint settings from remote
    SETTINGS_LOADED, // Endpoint settings loaded from remote
    LOGIN_REQUIRED, // Endpoint settings loaded from remote but user hasn't sign in yet
    LOGGING_IN, // Endpoint settings loaded from remote and trying to login the user
    LOGIN_FAILED, // Endpoint settings loaded but user login failed
    FULLY_LOADED// Endpoint settings loaded and user login succeed
}

export const MIN_SANDBOX_VOLUME_SIZE_IN_GB = 10;
export const MAX_SANDBOX_VOLUME_SIZE_IN_GB = 16384;

export const MIN_SANDBOX_IDLE_TIME_SETTING_IN_MIN = 1;
export const MAX_SANDBOX_IDLE_TIME_SETTING_IN_MIN = 4320;

export const MIN_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE = 1;
export const MAX_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE = 100;

export enum PLATFORM_IDS {
    AL1 = "notebook-al1-v1",
    AL2 = "notebook-al2-v2"
}

export const TROUBLESHOOTING_SIM_LINK =
    "https://sim.amazon.com/issues/create?assignedFolder=c028df3b-6e5f-4ac6-bbeb-b3f78002bc26";

// The user status is aiming to matched the possible user status defined under sandbox management service model
// https://code.amazon.com/packages/SecureAISandboxManagementServiceLambdaModel/blobs/3dbf482a890cda8c1729268acd8f33f82b232a35/--/model/type/user-type.xml#L28-L40
export enum USER_STATUS {
    INACTIVE,
    ACTIVATING,
    ACTIVE,
    DEACTIVATING,
    DEACTIVATED,
    UNKNOWN
}

export function stringToUserStatus(status: string): USER_STATUS {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "inactive") {
        return USER_STATUS.INACTIVE;
    } else if (lowerCaseStatus === "activating") {
        return USER_STATUS.ACTIVATING;
    } else if (lowerCaseStatus === "active") {
        return USER_STATUS.ACTIVE;
    } else if (lowerCaseStatus === "deactivating") {
        return USER_STATUS.DEACTIVATING;
    } else if (lowerCaseStatus === "deactivated") {
        return USER_STATUS.DEACTIVATED;
    } else if (lowerCaseStatus === "unknown") {
        return USER_STATUS.UNKNOWN;
    } else {
        throw new Error(`Unrecognized user status "${status}"`);
    }
}

export enum SANDBOX_STATUS {
    FAILED,
    IN_SERVICE,
    STOPPED,
    UPDATING,
    CREATING,
    STARTING,
    STOPPING,
    DELETING,
    DELETED,
}

export function stringToSandboxStatus(status: string): SANDBOX_STATUS {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "failed") {
        return SANDBOX_STATUS.FAILED;
    } else if (lowerCaseStatus === "inservice") {
        return SANDBOX_STATUS.IN_SERVICE;
    } else if (lowerCaseStatus === "stopped") {
        return SANDBOX_STATUS.STOPPED;
    } else if (lowerCaseStatus === "updating") {
        return SANDBOX_STATUS.UPDATING;
    } else if (lowerCaseStatus === "creating") {
        return SANDBOX_STATUS.CREATING;
    } else if (lowerCaseStatus === "starting") {
        return SANDBOX_STATUS.STARTING;
    } else if (lowerCaseStatus === "stopping") {
        return SANDBOX_STATUS.STOPPING;
    } else if (lowerCaseStatus === "deleting") {
        return SANDBOX_STATUS.DELETING;
    } else if (lowerCaseStatus === "deleted") {
        return SANDBOX_STATUS.DELETED;
    } else {
        throw new Error(`Unrecognized sandbox status "${status}"`);
    }
}


export enum WORKFLOW_STATUS {
    DEPRECATED,
    ACTIVE,
}

export function stringToWorkflowStatus(status: string): WORKFLOW_STATUS {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "deprecated") {
        return WORKFLOW_STATUS.DEPRECATED;
    } else if (lowerCaseStatus === "active") {
        return WORKFLOW_STATUS.ACTIVE;
    } else {
        throw new Error(`Unrecognized workflow status "${status}"`);
    }
}


export enum WORKFLOW_EXECUTION_STATUS {
    FAILED,
    COMPLETE,
    RUNNING,
    VALIDATING,
    VALIDATED,
    PROVISIONING,
    PROVISIONED,
    STARTING,
    STOPPING,
    BLOCKED,
    EXECUTING,
    STOPPED,
    SUCCEEDED,
}

export function stringToWorkflowExecutionStatus(status: string): WORKFLOW_EXECUTION_STATUS {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "failed") {
        return WORKFLOW_EXECUTION_STATUS.FAILED;
    } else if (lowerCaseStatus === "complete") {
        return WORKFLOW_EXECUTION_STATUS.COMPLETE;
    } else if (lowerCaseStatus === "running") {
        return WORKFLOW_EXECUTION_STATUS.RUNNING;
    } else if (lowerCaseStatus === "validating") {
        return WORKFLOW_EXECUTION_STATUS.VALIDATING;
    } else if (lowerCaseStatus === "validated") {
        return WORKFLOW_EXECUTION_STATUS.VALIDATED;
    } else if (lowerCaseStatus === "provisioning") {
        return WORKFLOW_EXECUTION_STATUS.PROVISIONING;
    } else if (lowerCaseStatus === "provisioned") {
        return WORKFLOW_EXECUTION_STATUS.PROVISIONED;
    } else if (lowerCaseStatus === "starting") {
        return WORKFLOW_EXECUTION_STATUS.STARTING;
    } else if (lowerCaseStatus === "stopping") {
        return WORKFLOW_EXECUTION_STATUS.STOPPING;
    } else if (lowerCaseStatus === "blocked") {
        return WORKFLOW_EXECUTION_STATUS.BLOCKED;
    } else if (lowerCaseStatus === "executing") {
        return WORKFLOW_EXECUTION_STATUS.EXECUTING;
    } else if (lowerCaseStatus === "stopped") {
        return WORKFLOW_EXECUTION_STATUS.STOPPED;
    } else if (lowerCaseStatus === "succeeded") {
        return WORKFLOW_EXECUTION_STATUS.SUCCEEDED;
    } else {
        throw new Error(`Unrecognized execution status "${status}"`);
    }
}

export enum SCHEDULE_STATUS {
    DEPRECATED,
    ACTIVE,
}

export function stringToScheduleStatus(status: string): SCHEDULE_STATUS {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "disabled") {
        return SCHEDULE_STATUS.DEPRECATED;
    } else if (lowerCaseStatus === "enabled") {
        return SCHEDULE_STATUS.ACTIVE;
    } else {
        throw new Error(`Unrecognized schedule status "${status}"`);
    }
}

export const INTERNAL_ERROR_MESSAGE = "Internal server error happened. Please contact service team.";