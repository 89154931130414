export const INFO_TABLE_COLUMN_DEFINITIONS = [
    {
        id: "name",
        header: () => "Property Name",
        cell: (item: any) => item.key,
        width: 360
    },
    {
        id: "value",
        header: () => "Property Value",
        cell: (item: any) => item.value,
    }
];