import React from "react";
import { Redirect } from "react-router-dom";
import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
} from "./table-config";
import {
    Flashbar,
    Table,
    TableContentSelector,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines, FormField, Input,
} from "@amzn/awsui-components-react";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { DistributionsHeader } from "./table-header.js";
import { TableNoMatchState } from "./components.js";
import "./table.css";
import AppContext from "../../../context/AppContext";
import {PageStage} from "../../../constants/constants";
import {getModsApiUrl, getOptions, verify} from "../../../auth/login";

/**
 * Refresh interval for the execution in seconds
 *
 * @type {number}
 */
const EXECUTION_TABLE_REFRESH_INTERVAL = 300;

const paginationLabels = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};

const distributionSelectionLabels = {
    itemSelectionLabel: (data, row) => `select ${row.id}`,
    allItemsSelectionLabel: () => "select all",
    selectionGroupLabel: "Distribution selection",
};

const generateColumnLabel =
    ({ id, header }) =>
    (sortState) => {
        const columnIsSorted = sortState.sortingColumn === id;
        const ascending = !sortState.sortingDescending;
        return `${typeof header === "function" ? header() : header}, ${
            columnIsSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
        }.`;
    };

const addColumnSortLabels = (columns, sortColumns) =>
    columns.map((col) => ({
        label: sortColumns.find((sortCol) => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
        ...col,
    }));

const columnDefinitions = addColumnSortLabels(COLUMN_DEFINITIONS, SORTABLE_COLUMNS);

class WorkflowExecutionsTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedExecutions: [],
            executions: props.executions ? props.executions : [],
            loading: true,
            dataLoaded: false,
            umlcFilter: new URLSearchParams({
                filter_type : "REQUESTER",
                requester: localStorage.getItem("userId"),
                page_size: 10,
                page_index: 0,
            }),
            pageIndex: 1,
            pageSize: 10,
            pagesCount: 2,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            wrapLines: false,
            redirect: false,
            link: "",
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
            showDeleteModal: false,
            deleteConfirmChecked: false,
        };

        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.onPreviousAndNextPageClick = this.onPreviousAndNextPageClick.bind(this);
        this.cancelExecution = this.cancelExecution.bind(this);
        this.retryFailedExecution = this.retryFailedExecution.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshButton = this.refreshButton.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.clearFlashbar = this.clearFlashbar.bind(this);
        this.redirectHome = this.redirectHome.bind(this);

    }

    redirectHome() {
        this.setState({
            redirect: true,
            link: "/",
        });
    }

    onPaginationChange({ detail }) {
        const query = this.state.umlcFilter
        const requestedPageIndex = (detail.currentPageIndex - 1).toString()
        const requestedPageSize = (detail.pageSize).toString()
        query.set("page_index", requestedPageIndex)
        query.set("page_size", requestedPageSize)
        this.setState({
            umlcFilter: query,
            pageSize: detail.pageSize,
            pageIndex: detail.currentPageIndex,
        });
        this.refresh()
    }

    onPreviousAndNextPageClick({ detail }) {
        const query = this.state.umlcFilter
        const requestedPage = (detail.requestedPageIndex - 1).toString()
        query.set("page_index", requestedPage)
        this.setState({
            umlcFilter: query,
            pageIndex: detail.requestedPageIndex,
        })
        this.refresh()
    }

    onContentSelectionChange({ detail }) {
        const contentSelection = detail.contentSelection;
        const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
        this.setState({
            contentSelectorOptions: [
                {
                    label: currentContentSelectorOptionGroup.label,
                    options: currentContentSelectorOptionGroup.options.map((opt) => ({
                        id: opt.id,
                        label: opt.label,
                        editable: opt.editable,
                        visible: contentSelection.indexOf(opt.id) !== -1,
                    })),
                },
            ],
        });
    }

    onWrapLinesChange({ detail }) {
        this.setState({
            wrapLines: detail.value,
        });
    }

    onSelectionChange({ detail }) {
        this.setState({
            selectedExecutions: detail.selectedItems,
        });
    }

    setUMLCFilter({ detail }) {
        const properties = detail.value.split(', ');
        const query = new URLSearchParams()
        properties.forEach(function(property) {
            const propertyKeyValue = property.split(':');
            query.append("filter_type", propertyKeyValue[0].toUpperCase())

            // Lowercase status will not be recognized. Convert to uppercase to prevent failure.
            if (propertyKeyValue[0].toUpperCase() === "STATUS") {
                query.append(propertyKeyValue[0].toLowerCase(), propertyKeyValue[1].toUpperCase())
            } else {
                query.append(propertyKeyValue[0].toLowerCase(), propertyKeyValue[1])
            }

            // If user try to filter by requester, set requester_only to true to show the result.
            if (propertyKeyValue[0].toUpperCase() === "REQUESTER") {
                query.append("requester_only", "true")
            }

        });

        query.append("page_size", this.state.pageSize)

        // If there is no requester filter, add one to prevent principalId missing error.
        if (!query.has("requester")) {
            query.append("filter_type", "REQUESTER")
            query.append("requester", localStorage.getItem("userId"))
        }
        this.setState({
                umlcFilter: query
            }
        )
        this.refresh()
    }

    getData(callback) {
        verify(async () => {
            const opts = await getOptions();
            opts.method = "GET";
            fetch(`${await getModsApiUrl()}/workflow_executions_cognito?${this.state.umlcFilter}`, opts)
                .then((res) => {
                    if (res.status === 500) {
                        this.showAlert("There is an internal server error. Please try again later.");
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data.WorkflowExecutionBriefs === undefined) {
                        callback([]);
                    } else {
                        callback(data.WorkflowExecutionBriefs);
                        const pagesCount = Math.ceil(data.resultSize/this.state.pageSize)
                        this.setState({
                            pagesCount: pagesCount
                        })
                    }
                })
                .catch(console.log);
        });
        this.setState({
            dataLoaded: true,
        });
    };

    formatData(executions) {
        let result = [];
        for (let i = 0; i < executions.length; i++) {
            let curr = executions[i];
            let newBox = {};
            newBox.executionId = curr.executionId;
            newBox.executionStatus = curr.executionStatus;
            newBox.templateName = curr.templateName;
            newBox.templateVersion = curr.templateVersion;
            newBox.startTime = new Date(curr.startTime * 1000).toLocaleString();
            newBox.requester = curr.requester;
            result.push(newBox);
        }
        return result;
    }

    componentDidMount() {
        console.debug("Accounts component being mounted. Current PageStage is", this.context.pageStage);
        if(PageStage.FULLY_LOADED === this.context.pageStage) {
            this.refresh();
            this.interval = setInterval(() => this.refresh(false), EXECUTION_TABLE_REFRESH_INTERVAL * 1000);
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (prevState.dataLoaded === false && this.state.dataLoaded === false) {
                console.debug("Page fully loaded and data never being loaded. Retrieve data from remote.");
                this.refresh();
                this.interval = setInterval(() => this.refresh(false), EXECUTION_TABLE_REFRESH_INTERVAL * 1000);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Refresh the table data
     *
     * @param pageJustLoaded Whether or not the page just loaded.
     *   If true, the spinner will show.
     *   If false, the table will just refresh without displaying the spinner, replacing the old data when ready.
     */
    refresh(pageJustLoaded = true) {
        if (pageJustLoaded && this.state.loading === false) {
            this.setState({ loading: true });
        }
        if (!localStorage.getItem("userId") || !localStorage.getItem("modsAccount")) {
            this.redirectHome();
            this.setState({ dataLoaded: true });
            return;
        }

        this.getData((executions) => {
            executions = executions === undefined ? [] : this.formatData(executions);

            this.setState({
                executions,
                loading: false,
            });
        });
    }

    refreshButton() {
        this.clearFlashbar();
        this.refresh();
    }


    cancelExecution() {
        this.showAlert("This function is under construction");
    }

    retryFailedExecution() {
        this.showAlert("This function is under construction");
    }

    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
        });
    }

    showSuccess(message) {
        this.setState({
            showSuccess: true,
            success: message,
        });
    }

    clearFlashbar() {
        this.setState({
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.link} />;
        }
        return (
            <div>
                <h1>My Team Workflow Executions</h1>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    dismiss: this.clearFlashbar,
                                },
                            ]}
                        />
                    </div>
                ) : null}
                <SpaceBetween size="l">
                    <FormField
                        label="UMLC search"
                        description={
                            <>
                                Provide UMLC filters. See{" "}
                                <a href="https://w.amazon.com/bin/view/CMLS/Overview/MODS/">User Guide</a> for more
                                search query information.
                            </>
                        }
                    >
                        <Input
                            onChange={this.setUMLCFilter.bind(this)}
                            value={""}
                            placeholder="Search Example: requester:userAlias, started_date_after:12/12/2022"
                            type="search"
                        />
                    </FormField>
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={this.state.executions}
                        stickyHeader={true}
                        resizableColumns={true}
                        header={
                            <div>
                                <DistributionsHeader
                                    selectedItems={this.state.selectedExecutions}
                                    updateTools={this.props.updateTools}
                                    refresh={this.refreshButton}
                                    cancelExecution={this.cancelExecution}
                                    retryFailedExecution={this.retryFailedExecution}
                                />
                            </div>
                        }
                        loading={this.state.loading}
                        noMatch={<TableNoMatchState />}
                        wrapLines={this.state.wrapLines}
                        onWrapLinesChange={this.onWrapLinesChange.bind(this)}
                    >
                        <TablePagination
                            onPaginationChange={this.onPaginationChange}
                            onPreviousPageClick={this.onPreviousAndNextPageClick}
                            onNextPageClick={this.onPreviousAndNextPageClick}
                            labels={paginationLabels}
                            pageSize={this.state.pageSize}
                            currentPageIndex={this.state.pageIndex}
                            pagesCount={this.state.pagesCount}
                        />
                        <TableSorting sortableColumns={SORTABLE_COLUMNS} />
                        <TableSelection
                            trackBy="executionId"
                            selectedItems={this.state.selectedExecutions}
                            labels={distributionSelectionLabels}
                            onSelectionChange={this.onSelectionChange.bind(this)}
                        />
                        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
                            <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                            <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                            <TableContentSelector
                                title="Select visible columns"
                                options={this.state.contentSelectorOptions}
                            />
                        </TablePreferences>
                    </Table>
                </SpaceBetween>
            </div>
        );
    }
}

export default WorkflowExecutionsTable;
