import { CognitoAuth } from "amazon-cognito-auth-js";
import {PageStage} from "../constants/constants";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export function getDevelopmentSettings() {
    return {
        cognitoDomain: "secure-ai-sandbox-management-beta.auth.us-east-1.amazoncognito.com",
        cognitoClientId: "3e9908ea1f6bi0o5h05tveo3na",
        domain: "http://localhost:3000",
        cognitoUserPoolId: "us-east-1_RH2yJqSbC",
        sandboxManagementServiceApi: "https://api.na.devo.secure-ai-sandbox.ctps.amazon.dev",
        modsWorkflowManagementServiceApi: "https://cczzjx36z3.execute-api.us-east-1.amazonaws.com/Beta"
    };
}

export const getSettings = async () => {
    if (isDev) {
        return getDevelopmentSettings();
    } else {
        return fetch("/settings.json")
            .then((r) => r.json());
    }
};

export const getAuth = async () => {
    const settings = await getSettings();
    const host = isDev ? "http://localhost:3000" : `https://${settings.domain}`;

    const auth = new CognitoAuth({
        AppWebDomain: settings.cognitoDomain, // The domain name in Cognito "App integration > Domain name", without https://
        ClientId: settings.cognitoClientId, // the client ID from Cognito "General settings > App clients" page
        RedirectUriSignIn: host, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
        RedirectUriSignOut: host, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
        TokenScopesArray: ["openid", "email"],
        UserPoolId: settings.cognitoUserPoolId, // the user pool from Cognito "General settings" page
    });

    return auth;
};

export function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split("?")[0];
        window.history.replaceState(undefined, "Secure AI Sandbox", newHref);
    }
}

// This is the callback function of the sign in button. It actually call sign out function, which behind the scene removes
//the local session and redirects to Cognito sign out URL. After completion it will redirect to this page again. So there
// is no need to call auth.getSession() in this function.
//function signInButtonOnClick() {
//    auth.signOut();
//}

export async function getOptions() {
    const auth = await getAuth();
    const session = auth.getSignInUserSession();
    const opts = {
        mode: "cors",
        headers: {
            Authorization: session.getIdToken().getJwtToken(), // NOT getAccessToken()
            "Content-Type": "application/x-amz-json-1.1",
        },
    };
    return opts;
}

export function getApiUrl() {
    return getSettings().then(r => r.sandboxManagementServiceApi);
}

export function getModsApiUrl() {
    return getSettings().then(r => r.modsWorkflowManagementServiceApi);
}

export async function verify(callback) {
    // Make user you turn on OAuth2 Authorization Code Grant flow
    const auth = await getAuth();
    auth.useCodeGrantFlow();

    // Register callback functions
    auth.userhandler = {
        onFailure: (_) => {
            removeQueryFromLocation();
        },
        onSuccess: (_) => {
            removeQueryFromLocation();
            // You have logged in :-)
            try {
                localStorage.setItem(
                    "userId",
                    auth.getSignInUserSession().getIdToken().decodePayload().identities[0].userId
                );
            } catch (e) {
                console.log(e);
            }
            callback();
        },
    };
    const href = window.location.href;
    const session = auth.getSignInUserSession();

    if (session.isValid()) {
        try {
            localStorage.setItem(
                "userId",
                auth.getSignInUserSession().getIdToken().decodePayload().identities[0].userId
            );
        } catch (e) {
            console.log(e);
        }
        callback();
    } else if (href.indexOf("?") > 0) {
        // This is required because Cognito needs to get the authentication result from the query string
        // The parsing is done asynchronously, and the result will be passed to the userHandler.
        auth.parseCognitoWebResponse(href);
    } else {
        // Cognito SDK will handle session refresh / authentication.
        auth.getSession();
    }
}
