import React from "react";
import { Icon, Popover } from "@amzn/awsui-components-react";
import { SCHEDULE_STATUS, stringToScheduleStatus } from "../../../constants/constants";

function getStatusClass(status: SCHEDULE_STATUS): string {
    const statusPrefix = "awsui-util-status-";
    let statusSuffix;
    switch (status) {
        case SCHEDULE_STATUS.ACTIVE:
            statusSuffix = "positive";
            break;
        case SCHEDULE_STATUS.DEPRECATED:
            statusSuffix = "negative";
            break;
        default:
            statusSuffix = "warn";
            break;
    }

    return statusPrefix + statusSuffix;
}

function getStatusIconName(status: SCHEDULE_STATUS): any {
    const iconPrefix = "status-";
    let iconSuffix;
    switch (status) {
        case SCHEDULE_STATUS.ACTIVE:
            iconSuffix = "positive";
            break;
        case SCHEDULE_STATUS.DEPRECATED:
            iconSuffix = "info";
            break;
        default:
            iconSuffix = "warn";
    }

    return iconPrefix + iconSuffix;
}

export const COLUMN_DEFINITIONS = [


    {
        id: "scheduleName",
        header: () => "Schedule Name",
        cell: (item: any) => (
            <div>
                <a href={`/schedules/${item.scheduleId}`}>{item.scheduleId}</a>
            </div>
        ),
        allowLineWrap: true,
    },

    {
        id: "scheduleStatus",
        cell: (item: any) => {
            const status = stringToScheduleStatus(item.scheduleStatus);
            const statusLabel = (
                <div className={getStatusClass(status)}>
                    <Icon name={getStatusIconName(status)} />
                    <span>{` ${item.scheduleStatus}`}</span>
                </div>
            );
            return statusLabel;
        },
        header: () => "Status",
        allowLineWrap: true,
    },
        {
        id: "startDate",
        header: () => "Start Date",
        cell: (item: any) => item.scheduleStartDate,
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS = [
    { id: "scheduleName", field: "scheduleName" },
    { id: "scheduleStatus", field: "scheduleStatus" },
    { id: "startDate", field: "startDate" },
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: "Main schedule properties",
        options: [
            { id: "scheduleName", label: "Schedule Name", editable: false, visible: true },
            { id: "scheduleStatus", label: "Schedule Status", editable: false, visible: true },
            { id: "startDate", label: "Start Date", editable: true, visible: true }
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 5, label: "5 Schedules" },
    { value: 10, label: "10 Schedules" },
    { value: 15, label: "15 Schedules" },
];



