import React from "react";
import { Redirect } from "react-router-dom";
import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
} from "./table-config";
import {
    Flashbar,
    Table,
    TableContentSelector,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines,
    Input,
    FormField
} from "@amzn/awsui-components-react";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { DistributionsScheduleHeader } from "./table-header";
import "./table.css";
import AppContext from "../../../context/AppContext";
import { PageStage } from "../../../constants/constants";
import { getApiUrl, getModsApiUrl, getOptions, verify } from "../../../auth/login";

/**
 * Refresh interval for the schedule in seconds
 *
 * @type {number}
 */
const SCHEDULE_TABLE_REFRESH_INTERVAL = 300;

const paginationLabels = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};
const distributionSelectionLabels = {
    itemSelectionLabel: (data, row) => `select ${row.id}`,
    allItemsSelectionLabel: () => "select all",
    selectionGroupLabel: "Distribution selection",
};

const generateColumnLabel =
    ({ id, header }) =>
        (sortState) => {
            const columnIsSorted = sortState.sortingColumn === id;
            const ascending = !sortState.sortingDescending;
            return `${typeof header === "function" ? header() : header}, ${columnIsSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
                }.`;
        };

const addColumnSortLabels = (columns, sortColumns) =>
    columns.map((col) => ({
        label: sortColumns.find((sortCol) => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
        ...col,
    }));

const columnDefinitions = addColumnSortLabels(COLUMN_DEFINITIONS, SORTABLE_COLUMNS);


class SchedulesTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            schedules: props.schedules ? props.schedules : [],
            loading: true,
            dataLoaded: false,
            pageIndex: 1,
            pageSize: 10,
            pagesCount: 1,
            paginateData: [],
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            selectedExecutions: [],
            wrapLines: false,
            redirect: false,
            link: "",
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
        };

        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.onPreviousAndNextPageClick = this.onPreviousAndNextPageClick.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshButton = this.refreshButton.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.clearFlashbar = this.clearFlashbar.bind(this);
        this.redirectHome = this.redirectHome.bind(this);
        this.getPageData = this.getPageData.bind(this);

    }

    redirectHome() {
        this.setState({
            redirect: true,
            link: "/",
        });
    }

    getPageData() {
        const listPaginateData = this.state.schedules.slice((this.state.pageIndex - 1) * this.state.pageSize, this.state.pageIndex * this.state.pageSize)
        this.setState({
            paginateData: listPaginateData
        })
    }

    onPaginationChange({ detail }) {
        const requestedPageIndex = (detail.currentPageIndex - 1).toString()
        const requestedPageSize = (detail.pageSize).toString()
        const count=Math.ceil(this.state.schedules.length/ this.state.pageSize)
        this.setState({
            pageSize: detail.pageSize,
            pageIndex: detail.currentPageIndex,
            pagesCount:count,
        });
        this.refresh();

    }

    onPreviousAndNextPageClick({ detail }) {
        const requestedPage = (detail.requestedPageIndex - 1).toString()
        this.setState({
            pageIndex: detail.requestedPageIndex,
        })
        this.getPageData();

    }

    onContentSelectionChange({ detail }) {
        const contentSelection = detail.contentSelection;
        const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
        this.setState({
            contentSelectorOptions: [
                {
                    label: currentContentSelectorOptionGroup.label,
                    options: currentContentSelectorOptionGroup.options.map((opt) => ({
                        id: opt.id,
                        label: opt.label,
                        editable: opt.editable,
                        visible: contentSelection.indexOf(opt.id) !== -1,
                    })),
                },
            ],
        });
    }

    onSelectionChange({ detail }) {
        this.setState({
            selectedExecutions: detail.selectedItems,
        });
    }

    onWrapLinesChange({ detail }) {
        this.setState({
            wrapLines: detail.value,
        });
    }


    getData(callback) {
        const pathQueries = {
            execution_aws_account_id: localStorage.getItem("modsAccount")
        }
        this.context.modsWorkflowManagementClient.listSchedules(pathQueries)
            .then((res) => {
                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                }
                return res.data;
            })
            .then((data) => {
                if (data.ScheduleBriefs === undefined) {
                    callback([]);
                } else {
                    callback(data.ScheduleBriefs);
                    const pagesCount = Math.ceil(data.ScheduleBriefs.length / this.state.pageSize)
                    this.setState({
                        pagesCount: pagesCount
                    })
                }
            })
            .catch(console.log);


        this.setState({
            dataLoaded: true,
        });
    }

    formatData(schedules) {
        let result = [];
        for (let i = 0; i < schedules.length; i++) {
            let curr = schedules[i];
            let newBox = {};
            newBox.scheduleId = curr.scheduleId;
            newBox.scheduleName = curr.scheduleName;
            newBox.ownerTeamId = curr.ownerTeamId;
            newBox.cronExpression = curr.cronExpression;
            newBox.scheduleStartDate = curr.scheduleStartDate.substring(5, 7)+"/"+curr.scheduleStartDate.substring(8, 10)+
            "/"+curr.scheduleStartDate.substring(0, 4)+" "+curr.scheduleStartDate.substring(11, 13)+" : "+curr.scheduleStartDate.substring(14, 16);

            newBox.scheduleStatus = curr.scheduleStatus;
            newBox.gitfarmPackage = curr.gitfarmPackage;
            newBox.templateName = curr.templateName;
            newBox.templateVersion = curr.templateVersion;
            newBox.executionDocumentName = curr.executionDocumentName;
            newBox.executionDocumentVersion = curr.executionDocumentVersion;
            newBox.executionAwsAccountId = curr.executionAwsAccountId;
            newBox.requester = curr.requester;
            result.push(newBox);
        }
        return result;
    }

    componentDidMount() {
        console.debug("Accounts component being mounted. Current PageStage is", this.context.pageStage);
        if (PageStage.FULLY_LOADED === this.context.pageStage) {
            this.refresh();
            this.interval = setInterval(() => this.refresh(false), SCHEDULE_TABLE_REFRESH_INTERVAL * 1000);
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (PageStage.FULLY_LOADED === this.context.pageStage) {
            if (prevState.dataLoaded === false && this.state.dataLoaded === false) {
                console.debug("Page fully loaded and data never being loaded. Retrieve data from remote.");
                this.refresh();
                this.interval = setInterval(() => this.refresh(false), SCHEDULE_TABLE_REFRESH_INTERVAL * 1000);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Refresh the table data
     *
     * @param pageJustLoaded Whether or not the page just loaded.
     *   If true, the spinner will show.
     *   If false, the table will just refresh without displaying the spinner, replacing the old data when ready.
     */
    refresh(pageJustLoaded = true) {
        if (pageJustLoaded && this.state.loading === false) {
            this.setState({ loading: true });
        }
        if (!localStorage.getItem("userId") || !localStorage.getItem("modsAccount")) {
            this.redirectHome();
            this.setState({ dataLoaded: true });
            return;
        }
        this.getData((schedules) => {
            schedules = schedules === undefined ? [] : this.formatData(schedules);
            this.setState({
                schedules,
                loading: false,
            });
            this.getPageData();
        });
    }

    refreshButton() {
        this.clearFlashbar();
        this.refresh();
    }


    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
        });
    }

    showSuccess(message) {
        this.setState({
            showSuccess: true,
            success: message,
        });
    }

    clearFlashbar() {
        this.setState({
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
        });
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.link} />;
        }
        return (
            <div>
                <h1>My Schedules</h1>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    dismiss: this.clearFlashbar,
                                },
                            ]}
                        />
                    </div>
                ) : null}
                <SpaceBetween size="l">
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={this.state.paginateData}
                        stickyHeader={true}
                        resizableColumns={true}
                        header={
                            <div>
                                <DistributionsScheduleHeader
                                    selectedItems={this.state.selectedExecutions}
                                    updateTools={this.props.updateTools}
                                    refresh={this.refreshButton}
                                />
                            </div>
                        }
                        loading={this.state.loading}
                        wrapLines={this.state.wrapLines}
                        onWrapLinesChange={this.onWrapLinesChange.bind(this)}
                    >
                        <TablePagination
                            onPaginationChange={this.onPaginationChange}
                            onPreviousPageClick={this.onPreviousAndNextPageClick}
                            onNextPageClick={this.onPreviousAndNextPageClick}
        
                            labels={paginationLabels}
                            pageSize={this.state.pageSize}
                            currentPageIndex={this.state.pageIndex}
                            pagesCount={this.state.pagesCount}
                        />
                        {/* <TableSorting sortableColumns={SORTABLE_COLUMNS} /> */}
                        {/* <TableSelection
                            trackBy="scheduleId"
                            selectedItems={this.state.selectedExecutions}
                            labels={distributionSelectionLabels}
                            onSelectionChange={this.onSelectionChange.bind(this)}
                        /> */}
                        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
                            <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                            <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                            <TableContentSelector
                                title="Select visible columns"
                                options={this.state.contentSelectorOptions}
                            />
                        </TablePreferences>
                    </Table>
                </SpaceBetween>
            </div>
        );
    }
}

export default SchedulesTable;