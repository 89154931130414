import React from 'react';
import { Button } from '@amzn/awsui-components-react';


export const getHeaderCounterText = (items, selectedItems) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`;
};

export const TableNoMatchState = props => (
  <div className="awsui-util-t-c awsui-util-mv-s">
    <b>No matches</b>
    <p className="awsui-util-mb-xs">We can't find a match.</p>
    <Button onClick={props.onClearFilter}>Clear filter</Button>
  </div>
);

export const TableHeader = ({ ...props }) => {
  let counter = props.counter;
  if (!counter) {
    counter = props.totalItems ? getHeaderCounterText(props.totalItems, props.selectedItems) : null;
  }
  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2> 
            {`${props.title} `}
            {counter ? (
              <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                {`${counter}`}
              </span>
            ) : null}
          </h2>
        </div>
        {props.actionButtons && <div className="awsui-util-action-stripe-group">{props.actionButtons}</div>}
      </div>
      {props.description && <div className="awsui-util-container-header-description">{props.description}</div>}
    </div>
  );
};
