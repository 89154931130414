import React, { useState } from "react";
import { Button, Icon } from "@amzn/awsui-components-react";
import { TableHeader } from "./components";
import { DeleteSandboxConfirmationModal } from "./delete-confirmation-modal";
import { SANDBOX_STATUS, stringToSandboxStatus } from "../../../constants/constants";

export const DistributionsHeader = ({ ...props }) => {
    const selected = props.selectedItems;
    const oneSelected = selected.length === 1;

    const [deleteSandboxModalVisible, setDeleteSandboxModalVisible] = useState(false);

    let stopped = false;
    let inService = false;
    let failed = false;
    for (let i = 0; i < selected.length; i++) {
        const curr = selected[i];
        const currStatus = stringToSandboxStatus(curr.status);

        if (!stopped) {
            stopped = currStatus === SANDBOX_STATUS.STOPPED;
        }
        if (!inService) {
            inService = currStatus === SANDBOX_STATUS.IN_SERVICE;
        }
        if (!failed) {
            failed = currStatus === SANDBOX_STATUS.FAILED;
        }
    }

    return (
        <>
            <TableHeader
                {...props}
                title="Sandboxes"
                actionButtons={
                    <div>
                        <Button onClick={props.refresh}>
                            <Icon name="refresh" />
                        </Button>
                        <Button
                            disabled={!oneSelected || !(stopped || failed)}
                            onClick={() => {
                                setDeleteSandboxModalVisible(true);
                            }}
                        >
                            Delete
                        </Button>

                        <Button disabled={!oneSelected} onClick={props.updateSandbox}>
                            Edit
                        </Button>
                        <Button disabled={!failed} onClick={props.retryUpdatingFailedSandbox}>
                            Retry
                        </Button>
                        <Button disabled={!stopped} onClick={props.startSandbox}>
                            Start
                        </Button>
                        <Button disabled={!inService} onClick={props.stopSandbox}>
                            Stop
                        </Button>
                        <Button variant="primary" href="/create">
                            Create Sandbox
                        </Button>
                    </div>
                }
            />

            <DeleteSandboxConfirmationModal
                deleteSandbox={props.deleteSandbox}
                visible={deleteSandboxModalVisible}
                setVisible={setDeleteSandboxModalVisible}
                sandboxId={selected[0] ? selected[0].sandboxId : ""}
            />
        </>
    );
};
