import { Header, SpaceBetween, StatusIndicator, Container, Box, ColumnLayout, ContentLayout } from "@amzn/awsui-components-react-v3";
import AppContext from "../../../context/AppContext";
import React from "react";
import {
    Button, ButtonDropdown,
    Flashbar, Icon,
    Table, TableContentSelector, TablePageSizeSelector,
    TablePagination, TablePreferences,
    TableSorting, TableWrapLines,
    Textarea
} from "@amzn/awsui-components-react";
import { PageStage } from "../../../constants/constants";
import { getStatusIndicator } from "./detail-table-config";
import { getModsApiUrl, getOptions, verify } from "../../../auth/login";


export const PageHeader = ({ ...props }) => {
    return (
        <Header
            variant="h1"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button disabled={!props.isEnabled} onClick={props.enableSchedule}>
                        Enable
                    </Button>
                    <Button disabled={props.isEnabled} onClick={props.disableSchedule}>
                        Disable
                    </Button>
                </SpaceBetween>
            }
        >
            {header}
        </Header>
    );
};

const paginationLabels = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};

const columnDefinitions = [
    {
        id: "executionId",
        header: "Execution ID",

        cell: (item) => (
            <div>
                <a href={`/workflow_executions/${item.executionId}`}>{item.executionId}</a>
            </div>
        ),
        width: 400,
        minWidth: 165,
        sortingField: "executionId",
        isRowHeader: true
    },
    {
        id: "status",
        header: "Status",
        cell: e => e.executionStatus,
        width: 200,
        minWidth: 110,
        sortingField: "status"
    },
    {
        id: "startTime",
        header: "Start Time",
        cell: e => e.startTime,
        width: 200,
        minWidth: 110,
        sortingField: "startTime"
    }
]


class ConfigTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            generalData: {},
            generalDataLoaded: false,
            showErrorMsg: false,
            errorMsg: "",
            isEnabled: false,
            wrapLines: false,
            loading: true,
            pageIndex: 1,
            pageSize: 10,
            pagesCount: 1,
            paginateData: [],
            workflows: props.workflows ? props.workflows : [],
        };
        this.getScheduleData = this.getScheduleData.bind(this);
        this.disableSchedule = this.disableSchedule.bind(this);
        this.enableSchedule = this.enableSchedule.bind(this);
        this.getPageData = this.getPageData.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.onPreviousAndNextPageClick = this.onPreviousAndNextPageClick.bind(this);
    }

    resetContentToLoading(ref) {
        ref.cleanAndSetToLoading();
    }

    setInfoContent(ref, contentToDisplay) {
        ref.setTableContent(contentToDisplay);
    }
    onWrapLinesChange({ detail }) {
        this.setState({
            wrapLines: detail.value,
        });
    }

    getPageData() {
        const listPaginateData = this.state.workflows.slice((this.state.pageIndex - 1) * this.state.pageSize, this.state.pageIndex * this.state.pageSize)
        this.setState({
            paginateData: listPaginateData
        })
    }

    onPaginationChange({ detail }) {
        const requestedPageIndex = (detail.currentPageIndex - 1).toString()
        const requestedPageSize = (detail.pageSize).toString()
        const count = Math.ceil(this.state.workflows.length / this.state.pageSize)
        this.setState({
            pageSize: detail.pageSize,
            pageIndex: detail.currentPageIndex,
            pagesCount: count,
        });
        this.getScheduleData();

    }

    onPreviousAndNextPageClick({ detail }) {
        const requestedPage = (detail.requestedPageIndex - 1).toString()
        this.setState({
            pageIndex: detail.requestedPageIndex,
        })
        this.getPageData();

    }

    getData(callback) {
        const pathQueries = {
            schedule_id: this.props.scheduleId
        }
        this.context.modsWorkflowManagementClient.listScheduledWorkflows(pathQueries)
            .then((res) => {
                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                }
                return res.data;
            })
            .then((data) => {
                if (data.ScheduleTriggeredWorkflows === undefined) {
                    callback([]);
                } else {
                    callback(data.ScheduleTriggeredWorkflows);
                    const pagesCount = Math.ceil(data.ScheduleTriggeredWorkflows.length / this.state.pageSize)
                    this.setState({
                        pagesCount: pagesCount
                    })
                }
            })
            .catch(console.log);

    }

    getScheduleData() {

        const currentLocationPath = window.location.pathname.split("/");
        const scheduleId = currentLocationPath[2];
        this.context.modsWorkflowManagementClient.getScheduleDetail(this.props.scheduleId).then((res) => {
            if (res.status === 500) {
                this.showAlert("There is an internal server error. Please try again later.");
            } else if (res.status !== 200) {
                this.showAlert(`Failed to retrieve workflow execution general information: ${res.data.message}`);
            }
            return res.data;
        })
            .then((data) => {
                const scheduleInfo = {
                    "scheduleId": this.props.scheduleId,
                    "ownerTeamId": data.scheduleInfo.ownerTeamId,
                    "cronExpression": data.scheduleInfo.cronExpression,
                    "scheduleStartDate": data.scheduleInfo.scheduleStartDate,
                    "scheduleStatus": data.scheduleInfo.scheduleStatus,
                    "gitfarmPackageName": data.scheduleInfo.gitfarmPackageName,
                    "templateName": data.scheduleInfo.templateName,
                    "templateVersion": data.scheduleInfo.templateVersion,
                    "executionDocumentName": data.scheduleInfo.executionDocumentName,
                    "executionDocumentVersion": data.scheduleInfo.executionDocumentVersion,
                    "executionAwsAccountId": data.scheduleInfo.executionAwsAccountId,
                    "requester": data.scheduleInfo.requester,
                }
                this.setState({
                    generalData: scheduleInfo,
                    generalDataLoaded: true,
                    isEnabled: scheduleInfo.scheduleStatus == "ENABLED" ? true : false,
                })

            })
            .catch(this.showAlert);

        this.getData((workflows) => {
            workflows = workflows === undefined ? [] : this.formatData(workflows);
            this.setState({
                workflows,
                loading: false,
            });
            this.getPageData();
        });

    };


    formatData(workflows) {
        let result = [];
        for (let i = 0; i < workflows.length; i++) {
            let curr = workflows[i];
            let newBox = {};
            newBox.executionId = curr.executionId;
            newBox.startTime = curr.startTime;
            newBox.executionStatus = curr.executionStatus;
            result.push(newBox);
        }
        return result;
    }


    enableSchedule() {
        this.context.modsWorkflowManagementClient.updateSchedules(
            this.state.generalData.scheduleId,
            this.state.generalData.ownerTeamId,
            this.state.generalData.cronExpression,
            this.state.generalData.scheduleStatus,
            this.state.generalData.scheduleStartDate,
            this.state.generalData.gitfarmPackageName,
            this.state.generalData.templateName,
            this.state.generalData.templateVersion,
            this.state.generalData.executionDocumentName,
            this.state.generalData.executionDocumentVersion,
            this.state.generalData.executionAwsAccountId,
            this.state.generalData.requester,
        ).then((res) => {
            if (res.status !== 200) {
                this.showAlert(`Failed to retrieve workflow execution general information: ${res.data.message}`);
            }
            return res.data;
        })
            .then((data) => {
                this.setState({
                    isEnabled: true,
                })
                this.getScheduleData();

            })
            .catch(console.log);


    }

    disableSchedule() {

        this.context.modsWorkflowManagementClient.updateSchedules(
            this.state.generalData.scheduleId,
            this.state.generalData.ownerTeamId,
            this.state.generalData.cronExpression,
            this.state.generalData.scheduleStatus,
            this.state.generalData.scheduleStartDate,
            this.state.generalData.gitfarmPackageName,
            this.state.generalData.templateName,
            this.state.generalData.templateVersion,
            this.state.generalData.executionDocumentName,
            this.state.generalData.executionDocumentVersion,
            this.state.generalData.executionAwsAccountId,
            this.state.generalData.requester,
        ).then((res) => {
            if (res.status === 500) {
                this.showAlert("There is an internal server error. Please try again later.");
            } else if (res.status !== 200) {
                this.showAlert(`Failed to retrieve workflow execution general information: ${res.data.message}`);
            }
            return res.data;
        })
            .then((data) => {
                this.setState({
                    isEnabled: false,
                })
                this.getScheduleData();
            })
            .catch(console.log);
    }


    showAlert(message) {
        this.setState({
            showErrorMsg: true,
            errorMsg: message,
        });
    }

    resetAlert() {
        this.setState({
            showErrorMsg: false,
            errorMsg: "",
        });
    }

    componentDidMount() {
        console.debug("Accounts component being mounted. Current PageStage is", this.context.pageStage);
        if (PageStage.FULLY_LOADED === this.context.pageStage) {
            this.getScheduleData();
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (PageStage.FULLY_LOADED === this.context.pageStage) {
            if (!prevState.generalDataLoaded && !this.state.generalDataLoaded) {
                console.debug("Page fully loaded and execution detail never being loaded. Retrieve execution detail from remote.");
                this.getScheduleData();
            }
        }
    }

    render() {
        return (
            <div className="col-xxs-10">
                {this.state.showErrorMsg &&
                    <div>
                        <Flashbar items={[{ type: "error", content: this.state.errorMsg, dismissible: true }]} />
                    </div>
                }
                <ContentLayout
                    header={

                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={this.state.isEnabled} onClick={this.enableSchedule}>
                                Enable
                            </Button>
                            <Button disabled={!this.state.isEnabled} onClick={this.disableSchedule}>
                                Disable
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size="l">
                        <Container header={<Header variant="h2">General Information</Header>}>
                            <ColumnLayout columns={4} variant="text-grid">
                                <div>
                                    <Box variant="awsui-key-label">Schedule Name</Box>
                                    <div>{this.state.generalData.scheduleId}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Schedule Cron Expression</Box>
                                    <div>{this.state.generalData.cronExpression}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Schedule Creation Time</Box>
                                    <div>{this.state.generalData.scheduleStartDate}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Schedule Status</Box>
                                    <StatusIndicator type={getStatusIndicator(this.state.generalData.scheduleStatus)}>{this.state.generalData.scheduleStatus}</StatusIndicator>
                                </div>
                            </ColumnLayout>
                        </Container>
                        <Container header={<Header variant="h2">Template Information</Header>}>
                            <ColumnLayout columns={3} variant="text-grid">
                                <div>
                                    <Box variant="awsui-key-label">Gitfarm Package Name</Box>
                                    <div>{this.state.generalData.gitfarmPackageName}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Template Name</Box>
                                    <div>{this.state.generalData.templateName}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Template Version</Box>
                                    <div>{this.state.generalData.templateVersion}</div>
                                </div>
                            </ColumnLayout>
                        </Container>

                        <SpaceBetween size="l">
                            <Table
                                columnDefinitions={columnDefinitions}
                                items={this.state.paginateData}
                                stickyHeader={true}
                                resizableColumns={true}
                                header={
                                    <Header>Workflow Executions</Header>
                                }
                                loading={this.state.loading}
                                wrapLines={this.state.wrapLines}
                                onWrapLinesChange={this.onWrapLinesChange.bind(this)}
                            >
                                <TablePagination
                                    onPaginationChange={this.onPaginationChange}
                                    onPreviousPageClick={this.onPreviousAndNextPageClick}
                                    onNextPageClick={this.onPreviousAndNextPageClick}

                                    labels={paginationLabels}
                                    pageSize={this.state.pageSize}
                                    currentPageIndex={this.state.pageIndex}
                                    pagesCount={this.state.pagesCount}
                                />
                            </Table>

                        </SpaceBetween>

                    </SpaceBetween>
                </ContentLayout>
            </div>
        );
    }
};
export default ConfigTable;

