import React from "react";
import {Table} from "@amzn/awsui-components-react";
import AppContext from "../../../context/AppContext";
import {INFO_TABLE_COLUMN_DEFINITIONS} from "./table-config";

/**
 * This is a Component that populate a input json string to a table.
 */
class InfoTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            itemsToDisplay: [],
            loading: true,
            wrapLines: false,
        };
    }

    cleanAndSetToLoading() {
        this.setState({
            itemsToDisplay: [],
            loading: true,
        })
    }

    setTableContent(json) {
        this.setState({
            itemsToDisplay: this.flatJson(json),
            loading: false,
        })
    }

    flatJson(json) {
        const flattenedJson = [];
        if (json !== undefined && json !== null ) {
            Object.keys(json).forEach(function(key) {
                flattenedJson.push({key: key, value: json[key]});
            });
        }
        return flattenedJson;
    }

    render() {
        return (
            <Table loading={this.state.loading}
                   items={this.state.itemsToDisplay}
                   columnDefinitions={INFO_TABLE_COLUMN_DEFINITIONS}
            />
        );
    }
};

export default InfoTable;