import React from "react";
import { Alert, AppLayout, Button, Flashbar, Form, FormField, Icon, Input, Select } from "@amzn/awsui-components-react";
import { Redirect } from "react-router-dom";

import SideBar from "../../navigation/sidebar.js";
import { getApiUrl, getOptions, verify } from "../../auth/login.js";

import "./update.css";

import { instanceTypes, instance_additional_gpu_info} from "../instance-types.ts";

import {
    enforceSandboxIdleCpuUtilInput,
    enforceSandboxIdleTimeInput,
    enforceSandboxStorageInput,
} from "../../util/form-utils";
import AppContext from "../../context/AppContext";
import {getSidebarItemFromPageStage} from "../home/home";

class DataProvider {
    getData(callback) {
        verify(async () => {
            const opts = await getOptions();
            opts.method = "GET";
            const user = `${localStorage.getItem("userId")}`;
            const account = localStorage.getItem("account");
            fetch(`${await getApiUrl()}/list_sandboxes?user_alias=${user}&aws_account_id=${account}`, opts)
                .then((res) => res.json())
                .then((data) => {
                    callback(data.sandboxInfos);
                })
                .catch(console.log);
        });
    }
}

const FormButtons = (props) => {
    if (!props.status || props.status === "running") {
        return null;
    }
    return (
        <div>
            <Button href="/sandboxes" variant="link">
                Cancel
            </Button>
            <Button
                variant="primary"
                onClick={() => {
                    props.history.push("/sandboxes");
                }}
            >
                Update
            </Button>
        </div>
    );
};

const SandboxSelector = (props) => {
    const sandboxes = props.sandboxes;
    const selected = props.selected;
    const redirect = props.redirect;
    const loaded = props.loaded;

    return (
        <div
            id="sandbox-selector"
            className={`awsui-util-container ${
                selected !== undefined && selected.status === "Stopped"
                    ? "sandbox-is-stopped"
                    : "sandbox-is-not-stopped"
            }`}
        >
            <div id="sandbox-select-header" className="awsui-util-container-header">
                <h2>Select a Sandbox</h2>
                <Button id="refresh-button" onClick={props.refresh}>
                    <Icon name="refresh" />
                </Button>
            </div>
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-6">
                        <FormField label="Instance Name" description="Select the instance you want to reconfigure.">
                            <Select
                                selectedLabel="Selected"
                                options={sandboxes}
                                selectedOption={
                                    selected
                                        ? {
                                              value: selected.sandboxId,
                                              label: selected.sandboxId,
                                          }
                                        : null
                                }
                                empty="No options"
                                placeholder="Choose a sandbox"
                                className="awsui-util-mb-xl"
                                ariaRequired={true}
                                onChange={(event) => {
                                    redirect(
                                        "/update/" + event.detail.selectedOption.id,
                                        event.detail.selectedOption.id
                                    );
                                }}
                                loading={!loaded}
                            />
                        </FormField>
                    </div>
                    <div className="col-6">
                        {selected ? (
                            <div>
                                <div
                                    className={`awsui-util-status-${
                                        selected.status === "Stopped"
                                            ? "inactive"
                                            : selected.status === "InService"
                                            ? "positive"
                                            : "info"
                                    }`}
                                >
                                    <Icon
                                        name={
                                            selected.status === "Stopped"
                                                ? "status-negative"
                                                : selected.status === "InService"
                                                ? "status-positive"
                                                : "status-in-progress"
                                        }
                                    />
                                    <span>{!loaded ? ` loading...` : ` ${selected.status}`}</span>
                                </div>
                                <Button
                                    disabled={selected.status !== "Stopped" && selected.status !== "InService"}
                                    onClick={() => {
                                        switch (selected.status) {
                                            case "Stopped":
                                                props.startSandbox();
                                                break;
                                            case "InService":
                                                props.stopSandbox();
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {(() => {
                                        switch (selected.status) {
                                            case "Stopped": {
                                                return "Start";
                                            }
                                            case "InService": {
                                                return "Stop";
                                            }
                                            default: {
                                                return "In Progress...";
                                            }
                                        }
                                    })()}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

class SandboxConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
            configs: instanceTypes,
            type: props.selected.type,
            size: props.selected.size,
            idleThreshold: props.selected.idleThreshold,
            idleTime: props.selected.idleTime,
        };

        this.update = this.update.bind(this);
    }

    update() {
        const newConfig = {
            sandboxId: this.state.selected.sandboxId,
            sandboxProperties: {
                diskSizeInGB: this.state.size,
                sandboxInstanceType: this.state.type,
            },
            sandboxLifeCycleConfig: {
                idleTimeToStopInMin: this.state.idleTime,
                idleCPUThreshold: this.state.idleThreshold,
            },
        };
        this.props.updateSandbox(newConfig);
    }

    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div className="awsui-util-container col-l-8 col-xl-7 col-m-9 col-s-12 col-xs-12 col-xxs-12">
                <div className="awsui-util-container-header">
                    <h2>Change Configuration</h2>
                </div>
                <div>
                    <FormField label="Instance Type" description="Provide the type of your instance.">
                        <Select
                            selectedLabel="Selected"
                            options={instanceTypes.type_groups}
                            selectedOption={{ value: this.state.type, label: this.state.type }}
                            empty="No options"
                            placeholder="Choose a type"
                            className="awsui-util-mb-xl"
                            ariaRequired={true}
                            onChange={({ detail }) => {
                                this.setState({ type: detail.selectedOption.id });
                            }}
                            disabled={this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed" }
                        />
                    </FormField>

                    <label id="instanceMessage">The instance {this.state.type} offers {instance_additional_gpu_info(this.state.type)}
                    </label>
                    <br/>
                    <br/>

                    <div className="sandbox-form-text">
                        <FormField label="Storage Size" description="Indicate the storage size of your instance in GB.">
                            <Input
                                type="number"
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxStorageInput(inputNumber, (value) => this.setState({ size: value }));
                                }}
                                value={this.state.size.toString()}
                                disabled={this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed"}
                            />
                        </FormField>
                    </div>
                    <div className="sandbox-form-text">
                        <FormField
                            label="Instance Uptime"
                            description="Provide how long your instance can be idle before automatically stopping in minutes."
                        >
                            <Input
                                type="number"
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxIdleTimeInput(inputNumber, (value) =>
                                        this.setState({ idleTime: value })
                                    );
                                }}
                                value={this.state.idleTime.toString()}
                                disabled={this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed"}
                            />
                        </FormField>
                    </div>
                    <div className="sandbox-form-text">
                        <FormField
                            label="Idle Threshold"
                            description="Designate the CPU usage threshold for this sandbox to be considered idle."
                        >
                            <Input
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxIdleCpuUtilInput(inputNumber, (value) =>
                                        this.setState({ idleThreshold: value })
                                    );
                                }}
                                value={this.state.idleThreshold.toString()}
                                type="number"
                                disabled={this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed"}
                            />
                        </FormField>
                    </div>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <Button
                                id="update-button"
                                variant="primary"
                                disabled={
                                    (this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed")||
                                    (this.state.type === this.state.selected.type &&
                                        this.state.size === this.state.selected.size &&
                                        this.state.idleTime === this.state.selected.idleTime &&
                                        this.state.idleThreshold === this.state.selected.idleThreshold)
                                }
                                onClick={this.update}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class UpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sandboxes: [],
            selectOptions: [],
            loaded: false,
            stopped: false,
            failed: false,
            selected: this.props.selected,
            link: "/update",
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
        };

        this.redirect = this.redirect.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshButton = this.refreshButton.bind(this);
        this.startSandbox = this.startSandbox.bind(this);
        this.stopSandbox = this.stopSandbox.bind(this);
        this.updateSandbox = this.updateSandbox.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refreshButton() {
        if (this.state.loaded === true) {
            this.setState({
                showAlert: false,
                showSuccess: false,
            });
        }
        this.refresh();
        this.setState({
            showAlert: false,
            showSuccess: false,
        });
    }

    refresh() {
        if (localStorage.getItem("account") === null) {
            return;
        }
        if (this.state.loaded === true) {
            this.setState({
                loaded: false,
            });
        }
        new DataProvider().getData((sandboxes) => {
            sandboxes = sandboxes.map((sandbox) => ({
                sandboxId: sandbox.sandboxId,
                status: sandbox.sandboxStatus,
                size: sandbox.sandboxProperties.diskSizeInGB,
                type: sandbox.sandboxProperties.sandboxInstanceType,
                idleTime: sandbox.sandboxLifeCycleConfig.idleTimeToStopInMin,
                idleThreshold: sandbox.sandboxLifeCycleConfig.idleCPUThreshold,
            }));
            const selectOptions = sandboxes.map((sandbox) => ({
                id: sandbox.sandboxId,
                label: sandbox.sandboxId,
            }));
            let selected = this.state.selected;
            if (selected) {
                if (typeof selected === "object") {
                    selected = sandboxes.find((sandbox) => sandbox.sandboxId === selected.sandboxId);
                } else if (sandboxes.map((sandbox) => sandbox.sandboxId).includes(selected)) {
                    selected = sandboxes.find((sandbox) => sandbox.sandboxId === selected);
                } else {
                    selected = undefined;
                }
            }
            const stopped = selected ? selected.status === "Stopped" : false;
            const failed = selected ? selected.status === "Failed" : false;
            const redirect = typeof selected === "object" ? !sandboxes.includes(selected) : selected ? true : false;
            if (redirect) {
                this.redirect("/update", undefined);
            }
            this.setState({
                sandboxes,
                selectOptions,
                selected,
                stopped,
                failed,
                loaded: true,
            });
        });
    }

    startSandbox() {
        const selected = this.state.selected;
        if (selected.status !== "Stopped") {
            if (selected.status === "InService") {
                this.showAlert("The selected sandbox is already started.");
            } else {
                this.showAlert("The selected sandbox is not stopped.");
            }
            return;
        }
        verify(async () => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxId: selected.sandboxId,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/start_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        this.refresh();
                        this.showSuccess("Sandbox is starting.");
                    } else {
                        if (res.status === 500) {
                            this.showAlert("There was an error with starting your sandbox. Please try again later.");
                        } else {
                            return res.json();
                        }
                    }
                })
                .then((resBody) => {
                    if (resBody === undefined) {
                        return;
                    }
                    if (resBody.message !== undefined && resBody.message !== null) {
                        this.showAlert(resBody.message);
                    } else {
                        this.showAlert("There was an error with starting your sandbox. Please try again later.");
                    }
                })
                .catch(this.showAlert);
        });
    }

    stopSandbox() {
        const selected = this.state.selected;
        if (selected.status !== "InService") {
            if (selected.status === "Stopped") {
                this.showAlert("The selected sandbox is already stopped.");
            } else {
                this.showAlert("The selected sandbox is not running.");
            }
            return;
        }
        verify(async () => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxId: selected.sandboxId,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/stop_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        this.refresh();
                        this.showSuccess("Sandbox is stopping.");
                    } else {
                        if (res.status === 500) {
                            this.showAlert("There was an error with stopping your sandbox. Please try again later.");
                        } else {
                            return res.json();
                        }
                    }
                })
                .then((resBody) => {
                    if (resBody === undefined) {
                        return;
                    }
                    if (resBody.message !== undefined && resBody.message !== null) {
                        this.showAlert(resBody.message);
                    } else {
                        this.showAlert("There was an error with stopping your sandbox. Please try again later.");
                    }
                })
                .catch(this.showAlert);
        });
    }

    updateSandbox(newConfig) {
        console.log(`Updating sandbox with parameters ${JSON.stringify(newConfig)}`);
        if (this.state.selected.status !== "Stopped" && this.state.selected.status !== "Failed") {
            this.showAlert("This sandbox is not stopped or failed. Please stop the sandbox before you update the configuration or make sure the last sandbox update failed before you retry.");
            return;
        } else if (newConfig === undefined) {
            this.showAlert("There was an error with the configuration. Please try again with a valid configuration.");
            return;
        } else if (newConfig.sandboxId === undefined) {
            this.showAlert("A valid sandbox was not selected. Please reselect a sandbox to update.");
            return;
        } else if (newConfig.sandboxProperties === undefined) {
            this.showAlert("There was an error with the properties of the sandbox.");
            return;
        } else if (
            newConfig.sandboxProperties.diskSizeInGB === undefined ||
            newConfig.sandboxProperties.diskSizeInGB < 1
        ) {
            this.showAlert("Please select a valid storage size (greater than 0).");
            return;
        } else if (newConfig.sandboxProperties.sandboxInstanceType === undefined) {
            this.showAlert("Please pick a valid instance type.");
            return;
        } else if (newConfig.sandboxLifeCycleConfig === undefined) {
            this.showAlert("There was an error with the configuration. Please try again with a valid configuration.");
            return;
        } else if (
            newConfig.sandboxLifeCycleConfig.idleTimeToStopInMin === undefined ||
            newConfig.sandboxLifeCycleConfig.idleTimeToStopInMin < 1
        ) {
            this.showAlert("Please select a valid idle time (greater than 0).");
            return;
        } else if (
            newConfig.sandboxLifeCycleConfig.idleCPUThreshold === undefined ||
            newConfig.sandboxLifeCycleConfig.idleCPUThreshold < 1
        ) {
            this.showAlert("Please select a valid idle threshold (greater than 0).");
            return;
        } else if (
            newConfig.sandboxProperties.diskSizeInGB === this.state.selected.size &&
            newConfig.sandboxProperties.sandboxInstanceType === this.state.selected.type &&
            newConfig.sandboxLifeCycleConfig.idleTimeToStopInMin === this.state.selected.idleTime &&
            newConfig.sandboxLifeCycleConfig.idleCPUThreshold === this.state.selected.idleThreshold
        ) {
            this.showAlert("Selected configuration matches current configuration. No update was made.");
            return;
        }
        verify(async () => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            if (user === undefined) {
                this.showAlert(
                    "there was a problem verifying your user alias. Please try again or contact CMLS for support if the issue persists."
                );
            }
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxInfo: newConfig,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/update_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        this.refresh();
                        this.showSuccess("Update was successful.");
                    } else {
                        if (res.status === 500) {
                            this.showAlert("There was an error with updating your sandbox. Please try again later.");
                        } else {
                            return res.json();
                        }
                    }
                })
                .then((resBody) => {
                    if (resBody === undefined) {
                        return;
                    }
                    if (resBody.message !== undefined && resBody.message !== null) {
                        this.showAlert(resBody.message);
                    } else {
                        this.showAlert("There was an error with updating your sandbox. Please try again later.");
                    }
                })
                .catch(this.showAlert);
        });
    }

    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
            showSuccess: false,
            success: "",
        });
    }

    showSuccess(message) {
        this.setState({
            showSuccess: true,
            success: message,
            showAlert: false,
            alert: "",
        });
    }

    redirect(url, sandbox) {
        this.props.history.push(url);
        this.setState({
            selected: sandbox,
            loaded: false,
            stopped: false,
        });
        this.refresh();
    }

    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    onDismiss: () =>
                                        this.setState({
                                            showAlert: false,
                                            alert: "",
                                            showSuccess: false,
                                            success: "",
                                        }),
                                },
                            ]}
                        />
                    </div>
                ) : null}

                <Alert visible={this.state.selected !== undefined && this.state.loaded && !this.state.stopped && !this.state.failed}>
                    Sandbox must be stopped or failed to update the configuration.
                </Alert>

                <Form actions={<FormButtons />}>
                    <SandboxSelector
                        selected={this.state.selected}
                        sandboxes={this.state.selectOptions}
                        redirect={this.redirect}
                        loaded={this.state.loaded}
                        refresh={this.refreshButton}
                        startSandbox={this.startSandbox}
                        stopSandbox={this.stopSandbox}
                    />
                    {this.state.loaded && this.state.sandboxes.length > 0 && typeof this.state.selected === "object" ? (
                        <SandboxConfig selected={this.state.selected} updateSandbox={this.updateSandbox} />
                    ) : null}
                </Form>
            </div>
        );
    }
}

const Content = (props) => (
    <div id="Update">
        <h1>Update Sandbox</h1>
        <UpdateForm selected={props.selected} history={props.history} />
    </div>
);

class Update extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <AppLayout
                content={<Content selected={this.props.match.params.sandboxId} history={this.props.history} />}
                navigation={<SideBar activeHref="/update" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default Update;
