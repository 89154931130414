import React from "react";
import { StatusIndicatorProps } from "@amzn/awsui-components-react-v3";

export function getStatusIndicator(status: string): StatusIndicatorProps.Type {
    let statusType;
    switch (status) {
        case "ENABLED":
            statusType = "success";
            break;
        case "DISABLED":
            statusType = "error";
            break;
        default:
            statusType = "info";
            break;
    }

    return statusType;
}
