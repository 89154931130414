import React from "react";
import { Button, Icon } from "@amzn/awsui-components-react";
import { TableHeader } from "../../workflows/table/components";

export const DistributionsScheduleHeader = ({ ...props }) => {

    return (
        <>
            <TableHeader
                {...props}
                title="Schedules"
                actionButtons={
                    <div>
                        <Button onClick={props.refresh}>
                            <Icon name="refresh" />
                        </Button>
                    </div>
                }
            />
        </>
    );
};
