import React from 'react';
import SideBar from '../../navigation/sidebar.js';
import { AppLayout } from '@amzn/awsui-components-react';
import ConfigTable  from './table/detail-table.js';
import AppContext from "../../context/AppContext";
import {getSidebarItemFromPageStage} from "../home/home";
import {Redirect} from "react-router-dom";

class WorkflowDetails extends React.Component {
    static contextType = AppContext;
    render() {
        if (!localStorage.getItem("modsAccount")) {
            return <Redirect to={"/"} />;
        }
        return (
            <AppLayout
                content={<ConfigTable />}
                navigation={<SideBar activeHref="/workflows" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default WorkflowDetails;
