import React from "react";
import { Icon } from "@amzn/awsui-components-react";
import { WORKFLOW_EXECUTION_STATUS, stringToWorkflowExecutionStatus } from "../../../constants/constants";

function getStatusClass(status: WORKFLOW_EXECUTION_STATUS): string {
    const statusPrefix = "awsui-util-status-";
    let statusSuffix;
    switch (status) {
        case WORKFLOW_EXECUTION_STATUS.RUNNING:
            statusSuffix = "info";
            break;
        case WORKFLOW_EXECUTION_STATUS.COMPLETE:
            statusSuffix = "positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.FAILED:
            statusSuffix = "negative";
            break;
        case WORKFLOW_EXECUTION_STATUS.VALIDATING:
            statusSuffix = "info";
            break;
        case WORKFLOW_EXECUTION_STATUS.VALIDATED:
            statusSuffix = "positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.PROVISIONING:
            statusSuffix = "info";
            break;
        case WORKFLOW_EXECUTION_STATUS.PROVISIONED:
            statusSuffix = "positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.STARTING:
            statusSuffix = "info";
            break;
        case WORKFLOW_EXECUTION_STATUS.STOPPING:
            statusSuffix = "info";
            break;
        case WORKFLOW_EXECUTION_STATUS.BLOCKED:
            statusSuffix = "negative";
            break;
        default:
            statusSuffix = "info";
            break;
    }

    return statusPrefix + statusSuffix;
}

function getStatusIconName(status: WORKFLOW_EXECUTION_STATUS): any {
    const iconPrefix = "status-";
    let iconSuffix;
    switch (status) {
        case WORKFLOW_EXECUTION_STATUS.RUNNING:
            iconSuffix = "in-progress";
            break;
        case WORKFLOW_EXECUTION_STATUS.COMPLETE:
            iconSuffix = "positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.FAILED:
            iconSuffix = "negative";
            break;
        case WORKFLOW_EXECUTION_STATUS.VALIDATING:
            iconSuffix = "in-progress";
            break;
        case WORKFLOW_EXECUTION_STATUS.VALIDATED:
            iconSuffix = "positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.PROVISIONING:
            iconSuffix = "in-progress";
            break;
        case WORKFLOW_EXECUTION_STATUS.PROVISIONED:
            iconSuffix = "in-positive";
            break;
        case WORKFLOW_EXECUTION_STATUS.STARTING:
            iconSuffix = "in-progress";
            break;
        case WORKFLOW_EXECUTION_STATUS.STOPPING:
            iconSuffix = "in-progress";
            break;
        case WORKFLOW_EXECUTION_STATUS.BLOCKED:
            iconSuffix = "negative";
            break;
        default:
            iconSuffix = "in-progress";
    }

    return iconPrefix + iconSuffix;
}

export const COLUMN_DEFINITIONS = [
    {
        id: "executionId",
        header: () => "Execution ID",
        cell: (item: any) => (
            <div>
                <a href={`/workflow_executions/${item.executionId}`}>{item.executionId}</a>
            </div>
        ),
        minWidth: "200px",
        allowLineWrap: true,
    },
    {
        id: "executionStatus",
        cell: (item: any) => {
            const status = stringToWorkflowExecutionStatus(item.executionStatus);
            const statusLabel = (
                <div className={getStatusClass(status)}>
                    <Icon name={getStatusIconName(status)} />
                    <span>{` ${item.executionStatus}`}</span>
                </div>
            );
            return statusLabel;

        },
        header: () => "Status",
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "templateName",
        header: () => "Workflow Definition",
        cell: (item: any) => item.templateName,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "templateVersion",
        header: () => "Version",
        cell: (item: any) => item.templateVersion,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "startTime",
        header: () => "Start Time",
        cell: (item: any) => item.startTime,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "requester",
        header: () => "Requester",
        cell: (item: any) => item.requester,
        minWidth: "100px",
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS = [
    { id: "executionId", field: "executionId" },
    { id: "executionStatus", field: "executionStatus" },
    { id: "templateName", field: "templateName" },
    { id: "templateVersion", field: "templateVersion" },
    { id: "startTime", field: "startTime" },
    { id: "requester", field: "requester" },
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: "Main workflow execution properties",
        options: [
            { id: "executionId", label: "Execution ID", editable: false, visible: true },
            { id: "executionStatus", label: "Status", editable: true, visible: true },
            { id: "templateName", label: "Workflow Definition", editable: true, visible: true },
            { id: "templateVersion", label: "Version", editable: true, visible: true },
            { id: "startTime", label: "Start Time", editable: true, visible: true },
            { id: "requester", label: "Requester", editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 5, label: "5 Executions" },
    { value: 10, label: "10 Executions" },
    { value: 15, label: "15 Executions" },
];

