import React from "react";
import { Alert, AppLayout, Flashbar, FormField, Select, Button } from "@amzn/awsui-components-react";
import SideBar, {full_items, home_page_only} from "../../navigation/sidebar.js";
import "./home.css";
import AppContext from "../../context/AppContext";
import {INTERNAL_ERROR_MESSAGE, PageStage, stringToUserStatus, USER_STATUS} from "../../constants/constants";

class Accounts extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            sandboxAccounts: [],
            selectedSandboxAccount: null,
            modsAccounts: [],
            selectedModsAccount: null,
            loading: true,
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
            accountLoaded: false,
        };

        this.getAccounts = this.getAccounts.bind(this);
        this.selectAccount = this.selectAccount.bind(this);
        this.selectModsAccount = this.selectModsAccount.bind(this);
    }

    setAccountList(awsAccounts) {
        awsAccounts = awsAccounts.map((curr) => ({
            id: curr.awsAccountId,
            label: curr.awsAccountName,
        }));
        let selectedAccount = null;
        const localAccount = localStorage.getItem("account");
        if (localAccount) {
            selectedAccount = awsAccounts.filter((obj) => {
                return obj.id === localAccount;
            });
            if (selectedAccount.length !== 1) {
                selectedAccount = null;
            } else {
                selectedAccount = selectedAccount[0];
            }
        }
        this.setState({
            sandboxAccounts: awsAccounts,
            selectedSandboxAccount: selectedAccount,
            loading: false,
        });
    }

    setModsAccountList(awsAccounts) {
        awsAccounts = awsAccounts.map((curr) => ({
            id: curr.awsAccountId,
            label: curr.awsAccountName,
        }));
        let selectedAccount = null;
        const localAccount = localStorage.getItem("modsAccount");
        if (localAccount) {
            selectedAccount = awsAccounts.filter((obj) => {
                return obj.id === localAccount;
            });
            if (selectedAccount.length !== 1) {
                selectedAccount = null;
            } else {
                selectedAccount = selectedAccount[0];
            }
        }
        this.setState({
            modsAccounts: awsAccounts,
            selectedModsAccount: selectedAccount,
            loading: false,
        });
    }

    getAccounts() {
        const user = localStorage.getItem("userId");
        const pathQueries ={
            requester: user
        }
        this.context.sandboxManagementClient
            .listAccountsForUserAlias(user)
            .then((res) => {
                if (res === undefined) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE);
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve accounts for you: ${res.data.message}`);
                } else {
                    let accounts = [];
                    if (res.data.awsAccountInfos !== undefined) {
                        accounts = res.data.awsAccountInfos;
                    }
                    this.setAccountList(accounts);
                }
            })
            .catch((error) => {
                console.error(`Failed to get account and user info: ${error}`);
                this.setAccountList([]);
            })

        this.context.modsWorkflowManagementClient
            .listRegisteredExecutionAwsAccounts(pathQueries)
            .then((res) => {
                if (res === undefined) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE);
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve mods accounts for you: ${res.data.message}`);
                } else {
                    let accounts = [];
                    if (res.data.executionAwsAccountInfos !== undefined) {
                        accounts =  res.data.executionAwsAccountInfos;
                    }
                    this.setModsAccountList(accounts);
                }
            })
            .catch((error) => {
                console.error(`Failed to get MODS account: ${error}`);
                this.setModsAccountList([]);
            })

        this.setState({accountLoaded: true})
    }

    selectAccount(account) {
        const successMessage = "Account select success.";
        const successButNotActiveUserMessage = "Login Success. You are not an active user to the selected account" +
            " Please move to \"My Account\" tab to activate your access.";

        const user = localStorage.getItem("userId");

        this.context.sandboxManagementClient
            .getAccountAndUserInfo(account, user)
            .then((res) => {
                if (res === undefined || res.status === 500) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE);
                } else if (res.status !== 200) {
                    this.showAlert(`Login failed with error: ${res.data.message}`);
                } else {
                    localStorage.setItem("account", account);
                    const retrievedUserStatus = stringToUserStatus(res.data.userInfo.userStatus);
                    if (retrievedUserStatus === USER_STATUS.ACTIVE) {
                        this.showSuccess(successMessage);
                    } else {
                        this.showSuccess(successButNotActiveUserMessage);
                    }
                }
            })
            .catch((error) => {
                console.error(`Unexpected exception happened when retrieve user and account information: ${error}`);
                this.showAlert(INTERNAL_ERROR_MESSAGE);
            });
    }

    selectModsAccount(account) {
        const successMessage = "MODS Account select success.";
        localStorage.setItem("modsAccount", account);
        this.showSuccess(successMessage);

    }

    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
            showSuccess: false,
            success: "",
        });
    }

    showSuccess(message) {
        this.setState({
            showAlert: false,
            alert: "",
            showSuccess: true,
            success: message,
        });
    }

    componentDidMount() {
        console.debug("Accounts component being mounted. Current PageStage is", this.context.pageStage);
        if(PageStage.FULLY_LOADED === this.context.pageStage) {
            this.getAccounts();
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (prevState.accountLoaded === false && this.state.accountLoaded === false) {
                console.debug("Page fully loaded and account never being loaded. Retrieve account list from remote.");
                this.getAccounts();
            }
        }
    }

    render() {
        const messageToUser = getPageStageInfo(this.context.pageStage);

        return (
            <div className="awsui-util-container awsui-util-mb-n">
                { PageStage.FULLY_LOADED === this.context.pageStage && <h2>Select an AWS Account</h2>}
                {this.state.showAlert || this.state.showSuccess ? (
                    <Flashbar
                        items={[
                            {
                                type: this.state.showAlert ? "error" : "success",
                                content: this.state.showAlert ? this.state.alert : this.state.success,
                                dismissible: true,
                                onDismiss: () =>
                                    this.setState({
                                        showAlert: false,
                                        alert: "",
                                        showSuccess: false,
                                        success: "",
                                    }),
                            },
                        ]}
                    />
                ) : null}
                {PageStage.FULLY_LOADED === this.context.pageStage ?
                    <div>
                        <Alert visible={!localStorage.getItem("account")}>Select an AWS account to continue.</Alert>

                        <FormField stretch={true} label="AWS Account for Sandbox" className="awsui-util-d-b">
                            <Select
                                selectedLabel="Selected"
                                options={this.state.sandboxAccounts}
                                selectedOption={this.state.selectedSandboxAccount}
                                empty="No options"
                                placeholder="Choose a sandbox account"
                                className="awsui-util-mb-xl"
                                ariaRequired={true}
                                loading={this.state.loading}
                                onChange={({detail}) => {
                                    this.setState({selectedSandboxAccount: detail.selectedOption});
                                    this.selectAccount(detail.selectedOption.id);
                                }}
                            />
                        </FormField>

                        <Alert visible={!localStorage.getItem("modsAccount")}>Select a MODS account to continue.</Alert>

                        <FormField stretch={true} label="AWS Account for MODS" className="awsui-util-d-b">
                            <Select
                                selectedLabel="Selected"
                                options={this.state.modsAccounts}
                                selectedOption={this.state.selectedModsAccount}
                                empty="No options"
                                placeholder="Choose a MODS account"
                                className="awsui-util-mb-xl"
                                ariaRequired={true}
                                loading={this.state.loading}
                                onChange={({detail}) => {
                                    this.setState({selectedModsAccount: detail.selectedOption});
                                    this.selectModsAccount(detail.selectedOption.id);
                                }}
                            />
                        </FormField>
                    </div>
                    :
                    <div>
                        <h2>Loading the page for you: </h2>
                        <p>{messageToUser} </p>
                    </div>
                }
            </div>
        );
    }
}

export const Content = (tryLoadAccounts: boolean) => (
    <div id="Home">
        <div className="awsui-grid awsui-util-p-s">
            <div id="home-header" className="awsui-row">
                <div className="col-xxs-12">
                    <div className="awsui-row">
                        <div
                            id="home-header-title"
                            className="col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6"
                        >
                            <div className="awsui-text-large">
                                <h1 className="awsui-text-large">
                                    <strong>Secure AI Sandbox</strong>
                                </h1>
                                <div>Manage and create your Secure AI Sandboxes</div>
                            </div>
                            <p>
                                Secure AI Sandbox is a consolidated environment for ad-hoc feature development, model
                                experiment and rule testing without security concern.
                            </p>
                        </div>
                        <div className="col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
                            { tryLoadAccounts && <Accounts /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="awsui-grid awsui-util-p-s">
            <div id="home-body" className="awsui-row">
                <div className="col-xxs-12">
                    <div className="awsui-row">
                        <div className="col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
                            <div className="awsui-util-container">
                                <div className="awsui-text-large">
                                    <h2 className="awsui-text-large">What is Secure AI Sandbox?</h2>
                                    <p>
                                        Secure AI Sandbox is a secure virtual machine which allows the client to do
                                        offline experiments with approved ML development software stacks pre-installed
                                        on the machine by leveraging the power of AWS deep learning support. We will
                                        also leverage the power of container technology to provide a smooth integration
                                        with Core services. Sandbox will audit and control the access to Core ML
                                        Services (CMLS) owned data lake, Modelling Data Service (MDS), as well as Amazon
                                        internal data exchange services such as EDX or Andes. Sandbox users will be able
                                        to export machine learning artifact and experiment results to CMLS owned ML
                                        pipeline by seamlessly integrating with existing CTPS Core ML products including
                                        On-The Fly (OTF) and Model management Service (MMS). Sandbox takes care of all
                                        maintenance efforts including patching, host replacement, and addressing policy
                                        engine risks. It also provides an option to back up the workspace on a regular
                                        basis to prevent loss of data due to host failure.
                                    </p>
                                </div>
                            </div>

                            <div className="awsui-util-container">
                                <div className="awsui-text-large">
                                    <h2 className="awsui-text-large">Who will it benefit?</h2>
                                    <p>
                                        Secure AI Sandbox aims to help all machine learning scientists with their daily
                                        needs for offline analysis, development, and research. It gives SDEs a better
                                        way to audit the data access and maintain tools needed by ML scientists and
                                        finally Amazon customers also benefit from it as it makes their confidential
                                        data more secure. Any ML scientist who has one of the following need should
                                        consider the use of Secure AI Sandbox (a) Need to access the data and manually
                                        build a model with Modeling Data Service (MDS) data (b) Need to do an experiment
                                        for new On The Fly (OTF) Compute feature logic(s) (c) Need to have a reliable
                                        work space with popular machine learning framework installed.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
                            <div className="awsui-util-container">
                                <div className="awsui-util-container-header">
                                    <h2>Help</h2>
                                </div>
                                <p>
                                    The Secure AI Sandbox is run by the CMLS team in CTPS. For more information on what
                                    we do click below.
                                </p>
                                <Button href="https://w.amazon.com/bin/view/CMLS/Overview/" variant="primary">
                                    CMLS Overview
                                </Button>
                                <p>
                                    MODS stands for  Model Training Workflow Operation and Development System. For more
                                    information and onboarding guide click below.
                                </p>
                                <Button href="https://w.amazon.com/bin/view/CMLS/Overview/MODS/" variant="primary">
                                    MODS User Guide
                                </Button>
                                <p>Need help? Submit a SIM or sign up for office hours with the link below.</p>
                                <Button href="https://w.amazon.com/bin/view/CMLS/ContactUs/" variant="primary">
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export function getPageStageInfo(pageStage: PageStage) {
    switch (pageStage) {
        case PageStage.SETTINGS_LOADING_REQUIRED:
        case PageStage.SETTINGS_LOADED:
        case PageStage.LOGIN_REQUIRED:
        case PageStage.LOGGING_IN:
            return "Loading website content. Please wait here...";
        case PageStage.LOGIN_FAILED:
            return "Login failed. Please contact service team.";
        case PageStage.FULLY_LOADED:
            return "Webpage fully loaded. Retrieving your accounts.";
        default:
            return "Something wrong happened. Please contact service team.";
    }
}

export function getSidebarItemFromPageStage(pageStage: PageStage) {
    if (pageStage === PageStage.FULLY_LOADED) {
        return full_items
    }
    return home_page_only
}

class Home extends React.Component {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                className="awsui-util-no-gutters"
                content={<Content tryLoadAccounts={PageStage.FULLY_LOADED === this.context.pageStage}/>}
                navigation={<SideBar activeHref="/" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
                headerSelector="#harmony-navbar"
            />
        );
    }
}

export default Home;
