import { Header, SpaceBetween,  StatusIndicator, Container, Box, ColumnLayout, ContentLayout} from "@amzn/awsui-components-react-v3";
import AppContext from "../../../context/AppContext";
import React from "react";
import {
    Button, ButtonDropdown,
    Flashbar, Icon,
    Table, TableContentSelector, TablePageSizeSelector,
    TablePagination, TablePreferences,
    TableSorting, TableWrapLines,
    Textarea
} from "@amzn/awsui-components-react";
import {PageStage} from "../../../constants/constants";
import {
    EXECUTION_TABLE_COLUMN_DEFINITIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
} from "./detail-table-config.tsx";
import {TableNoMatchState, TableHeader} from "./components";
import {getModsApiUrl, getOptions, verify} from "../../../auth/login";
import {CONTENT_SELECTOR_OPTIONS} from "../../workflow_executions/table/table-config";

export const PageHeader = ({ header, buttons }) => {
    return (
        <Header
            variant="h1"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    {buttons.map((button, key) =>
                        !button.items ? (
                            <Button href={button.href || ''} disabled={button.disabled || false} key={key}>
                                {button.text}
                            </Button>
                        ) : (
                            <ButtonDropdown items={button.items} key={key}>
                                {button.text}
                            </ButtonDropdown>
                        )
                    )}
                </SpaceBetween>
            }
        >
            {header}
        </Header>
    );
};

// the following section is for execution table that need templateName and templateVersion filter
// which will not be implemented at initial launch. Will uncomment this once the filter is implemented.

// const DistributionsHeader = ({ ...props }) => {
//
//     return (
//         <>
//             <TableHeader
//                 {...props}
//                 title="Workflow Executions"
//                 actionButtons={
//                     <div>
//                         <Button onClick={props.refresh}>
//                             <Icon name="refresh" />
//                         </Button>
//                     </div>
//                 }
//             />
//         </>
//     );
// };
//
// const EXECUTION_TABLE_REFRESH_INTERVAL = 300;
//
// const paginationLabels = {
//     nextPageLabel: "Next page",
//     previousPageLabel: "Previous page",
//     pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
// };
//
// const generateColumnLabel =
//     ({ id, header }) =>
//         (sortState) => {
//             const columnIsSorted = sortState.sortingColumn === id;
//             const ascending = !sortState.sortingDescending;
//             return `${typeof header === "function" ? header() : header}, ${
//                 columnIsSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
//             }.`;
//         };
//
// const addColumnSortLabels = (columns, sortColumns) =>
//     columns.map((col) => ({
//         label: sortColumns.find((sortCol) => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
//         ...col,
//     }));
//
// const columnDefinitions = addColumnSortLabels(EXECUTION_TABLE_COLUMN_DEFINITIONS, SORTABLE_COLUMNS);
//
// class ExecutionsTable extends React.Component {
//     static contextType = AppContext;
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             executions: props.executions ? props.executions : [],
//             loading: true,
//             dataLoaded: false,
//             umlcFilter: new URLSearchParams({
//                 filter_type : "REQUESTER",
//                 requester: localStorage.getItem("userId"),
//                 page_size: 10,
//                 page_index: 0,
//             }),
//             pageIndex: 1,
//             pageSize: 10,
//             pagesCount: 2,
//             contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
//             wrapLines: false,
//             redirect: false,
//             link: "",
//             showDeleteModal: false,
//             deleteConfirmChecked: false,
//         };
//
//         this.onPaginationChange = this.onPaginationChange.bind(this);
//         this.onPreviousAndNextPageClick = this.onPreviousAndNextPageClick.bind(this);
//         this.refresh = this.refresh.bind(this);
//         this.refreshButton = this.refreshButton.bind(this);
//
//     }
//
//     onPaginationChange({ detail }) {
//         const query = this.state.umlcFilter
//         const requestedPageIndex = (detail.currentPageIndex - 1).toString()
//         const requestedPageSize = (detail.pageSize).toString()
//         query.set("page_index", requestedPageIndex)
//         query.set("page_size", requestedPageSize)
//         this.setState({
//             umlcFilter: query,
//             pageSize: detail.pageSize,
//             pageIndex: detail.currentPageIndex,
//         });
//         this.refresh()
//     }
//
//     onPreviousAndNextPageClick({ detail }) {
//         const query = this.state.umlcFilter
//         const requestedPage = (detail.requestedPageIndex - 1).toString()
//         query.set("page_index", requestedPage)
//         this.setState({
//             umlcFilter: query,
//             pageIndex: detail.requestedPageIndex,
//         })
//         this.refresh()
//     }
//
//     onWrapLinesChange({ detail }) {
//         this.setState({
//             wrapLines: detail.value,
//         });
//     }
//
//     getData(callback) {
//         const currentLocationPath = window.location.pathname.split("/");
//         const templateName = currentLocationPath[2];
//         const templateVersion = currentLocationPath[3];
//         const query = this.state.umlcFilter
//         query.append("workflow_template_name", templateName)
//         query.append("workflow_template_version", templateVersion)
//         this.setState({
//                 umlcFilter: query
//             }
//         )
//         verify(async () => {
//             const opts = await getOptions();
//             opts.method = "GET";
//             fetch(`${await getModsApiUrl()}/workflow_executions_cognito?${this.state.umlcFilter}`, opts)
//                 .then((res) => {
//                     if (res.status === 500) {
//                         this.showAlert("There is an internal server error. Please try again later.");
//                     }
//                     return res.json();
//                 })
//                 .then((data) => {
//                     if (data.WorkflowExecutionBriefs === undefined) {
//                         callback([]);
//                     } else {
//                         callback(data.WorkflowExecutionBriefs);
//                         const pagesCount = Math.ceil(data.resultSize/this.state.pageSize)
//                         this.setState({
//                             pagesCount: pagesCount
//                         })
//                     }
//                 })
//                 .catch(console.log);
//         });
//         this.setState({
//             dataLoaded: true,
//         });
//     };
//
//     formatData(executions) {
//         let result = [];
//         for (let i = 0; i < executions.length; i++) {
//             let curr = executions[i];
//             let newBox = {};
//             newBox.executionId = curr.executionId;
//             newBox.executionStatus = curr.executionStatus;
//             newBox.templateName = curr.templateName;
//             newBox.templateVersion = curr.templateVersion;
//             newBox.startTime = new Date(curr.startTime * 1000).toLocaleString();
//             newBox.requester = curr.requester;
//             result.push(newBox);
//         }
//         return result;
//     }
//
//     componentDidMount() {
//         console.debug("Accounts component being mounted. Current PageStage is", this.context.pageStage);
//         if(PageStage.FULLY_LOADED === this.context.pageStage) {
//             this.refresh();
//             this.interval = setInterval(() => this.refresh(false), EXECUTION_TABLE_REFRESH_INTERVAL * 1000);
//         }
//     }
//
//     componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
//         if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
//             if (prevState.dataLoaded === false && this.state.dataLoaded === false) {
//                 console.debug("Page fully loaded and data never being loaded. Retrieve data from remote.");
//                 this.refresh();
//                 this.interval = setInterval(() => this.refresh(false), EXECUTION_TABLE_REFRESH_INTERVAL * 1000);
//             }
//         }
//     }
//
//     componentWillUnmount() {
//         clearInterval(this.interval);
//     }
//
//     /**
//      * Refresh the table data
//      *
//      * @param pageJustLoaded Whether or not the page just loaded.
//      *   If true, the spinner will show.
//      *   If false, the table will just refresh without displaying the spinner, replacing the old data when ready.
//      */
//     refresh(pageJustLoaded = true) {
//         if (pageJustLoaded && this.state.loading === false) {
//             this.setState({ loading: true });
//         }
//         if (!localStorage.getItem("userId") || !localStorage.getItem("modsAccount")) {
//             this.redirectHome();
//             this.setState({ dataLoaded: true });
//             return;
//         }
//
//         this.getData((executions) => {
//             executions = executions === undefined ? [] : this.formatData(executions);
//
//             this.setState({
//                 executions,
//                 loading: false,
//             });
//         });
//     }
//
//     refreshButton() {
//         this.clearFlashbar();
//         this.refresh();
//     }
//
//
//     render() {
//         return (
//             <div>
//                 <SpaceBetween size="l">
//                     <Table
//                         columnDefinitions={columnDefinitions}
//                         items={this.state.executions}
//                         stickyHeader={true}
//                         resizableColumns={true}
//                         header={
//                             <div>
//                                 <DistributionsHeader
//                                     selectedItems={this.state.selectedExecutions}
//                                     updateTools={this.props.updateTools}
//                                     refresh={this.refreshButton}
//                                 />
//                             </div>
//                         }
//                         loading={this.state.loading}
//                         noMatch={<TableNoMatchState />}
//                         wrapLines={this.state.wrapLines}
//                         onWrapLinesChange={this.onWrapLinesChange.bind(this)}
//                     >
//                         <TablePagination
//                             onPaginationChange={this.onPaginationChange}
//                             onPreviousPageClick={this.onPreviousAndNextPageClick}
//                             onNextPageClick={this.onPreviousAndNextPageClick}
//                             labels={paginationLabels}
//                             pageSize={this.state.pageSize}
//                             currentPageIndex={this.state.pageIndex}
//                             pagesCount={this.state.pagesCount}
//                         />
//                         <TableSorting sortableColumns={SORTABLE_COLUMNS} />
//                         <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
//                             <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
//                             <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
//                             <TableContentSelector
//                                 title="Select visible columns"
//                                 options={this.state.contentSelectorOptions}
//                             />
//                         </TablePreferences>
//                     </Table>
//                 </SpaceBetween>
//             </div>
//         );
//     }
// }

class ConfigTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            generalData: {},
            generalDataLoaded: false,
            infoLoaded: false,
            showErrorMsg: false,
            errorMsg: "",
            umlcLink: "/",
            executionLink: "/",
            templateName: "",
            templateVersion: "",
            gitfarmPackage:"",
            executionStructure: ""
        };


        this.getConfigData = this.getConfigData.bind(this);
        this.getGeneralData = this.getGeneralData.bind(this);

    }

    resetContentToLoading(ref) {
        ref.cleanAndSetToLoading();
    }

    setInfoContent(ref, contentToDisplay) {
        ref.setTableContent(contentToDisplay);
    }

    getGeneralData() {
        const currentLocationPath = window.location.pathname.split("/");
        const gitfarmPackage = currentLocationPath[2];
        const templateName = currentLocationPath[3];
        const templateVersion = currentLocationPath[4];
        this.context.modsWorkflowManagementClient.getWorkflowTemplateVersionDetail(gitfarmPackage, templateName, templateVersion)
            .then((res) => {
                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve workflow execution general information: ${res.data.message}`);
                }
                return res.data;
            })
            .then((data) => {
                this.setState({
                    generalData: data.workflowTemplateVersionInfo,
                    generalDataLoaded: true,
                    umlcLink: data.workflowTemplateVersionInfo.umlcPageUrl,
                    executionLink: "/" + "workflows" + "/" + gitfarmPackage + "/"+
                        templateName + "/" +
                        templateVersion + "/" + "execute",
                    templateName: data.workflowTemplateVersionInfo.templateName ,
                    templateVersion: data.workflowTemplateVersionInfo.templateVersion,
                    gitfarmPackage: data.workflowTemplateVersionInfo.gitfarmPackage
                })
            })
            .catch(console.log);
    };

    getConfigData() {
        const pathQueries = {
            gitfarm_package: this.state.gitfarmPackage,
            template_name: this.state.templateName,
            template_version: this.state.templateVersion
        }
        this.context.modsWorkflowManagementClient
            .getExecutionDocumentStructure(pathQueries)
            .then((res) => {
                if (res === undefined) {
                    this.showAlert("Internal failure");
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve workflow execution information: ${res.data.message}`);
                } else {
                    const url = res.data.executionDocumentStructureInfo.contentPresignedUrl;
                    fetch(url, {method: 'GET'})
                        .then(res => res.json())
                        .then(data => {
                            this.setState({
                                executionStructure: JSON.stringify(data,null, '  ')
                            })
                        })
                        .catch(error => console.log('Request failed', error));
                }
            })
            .catch((error) => {
                console.error(`Unexpected exception happened when retrieving information: ${error}`);
                this.showAlert("Internal failure");
            });

        this.setState({infoLoaded: true});
    }

    showAlert(message) {
        this.setState({
            showErrorMsg: true,
            errorMsg: message,
        });
    }

    resetAlert() {
        this.setState({
            showErrorMsg: false,
            errorMsg: "",
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.generalDataLoaded && !this.state.generalDataLoaded) {
                console.debug("Page fully loaded and execution detail never being loaded. Retrieve execution detail from remote.");
                this.getGeneralData();
            }
        }
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.infoLoaded && !this.state.infoLoaded && this.state.templateName) {
                console.debug("Page fully loaded and account never being loaded. Retrieve account list from remote.");
                this.getConfigData();
            }
        }
    }

    render() {
        return (
            <div className="col-xxs-10">
                { this.state.showErrorMsg &&
                    <div>
                        <Flashbar items={[{type: "error", content: this.state.errorMsg, dismissible: true}]}/>
                    </div>
                }
                <ContentLayout
                    header={
                        <PageHeader
                            //comment the version dropdown list since we plan to display all version for user to choose in template listing page
                            // buttons={[{ text: 'Versions', items: VERSION_DROPDOWN_ITEMS  }, { text: 'Link to UMLC' }, { text: 'Execute', href: '/workflows/:templateName/execute' }]}
                            header = {this.state.templateName}
                            buttons={[{ text: 'Link to UMLC', href: this.state.umlcLink }, { text: 'Execute', href: this.state.executionLink }]}
                        />
                    }
                >
                    <SpaceBetween size="l">
                        <Container header={<Header variant="h2">General Information</Header>}>
                            <ColumnLayout columns={4} variant="text-grid">
                                <div>
                                    <Box variant="awsui-key-label">Workflow Definition Name</Box>
                                    <div>{this.state.generalData.templateName}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Workflow Definition Creator</Box>
                                    <div>{this.state.generalData.templateAuthor}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Workflow Definition Creation Time</Box>
                                    <div>{new Date(this.state.generalData.createDate * 1000).toLocaleString()}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Workflow Status</Box>
                                    <StatusIndicator type="success">{this.state.generalData.templateStatus}</StatusIndicator>
                                </div>
                            </ColumnLayout>
                        </Container>
                        <Container header={<Header variant="h2">Execution structure</Header>}>
                            <Textarea
                                onChange={({ detail }) => setValue(detail.value)}
                                rows={this.state.executionStructure.split(/\r\n|\r|\n/).length}
                                value={this.state.executionStructure}
                                readOnly
                            />
                        </Container>
                        {/*<Container header={<Header variant="h2">Execution</Header>}>*/}
                        {/*    <ExecutionsTable />*/}
                        {/*</Container>*/}
                    </SpaceBetween>
                </ContentLayout>
            </div>
        );
    }
};
export default ConfigTable;

