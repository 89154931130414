import {CognitoAuth} from "amazon-cognito-auth-js";

export const LIST_ACCOUNTS_FOR_USER_ALIAS_PATH = "/get_aws_accounts_for_user_alias?user_alias={userAlias}";
export const GET_ACCOUNT_AND_USER_INFO_PATH = "/get_aws_account_and_user_info?user_alias={userAlias}&aws_account_id={awsAccountId}";
export const DELETE_SANDBOX_PATH = "/delete_sandbox";
export const ACTIVATE_USER_PATH = "/activate_user";

export class SandboxManagementClient {
    endpoint: string;
    auth: CognitoAuth;
    apigClient: any;

    constructor(endpoint: string, auth: CognitoAuth) {
        this.endpoint = endpoint;
        this.auth = auth;

        const apigClientFactory = require("aws-api-gateway-client").default;
        const config = {
            invokeUrl: endpoint,
            retries: 4,
            retryCondition: (err: any) => {
                return err.response && err.response.status === 500;
            }
        };
        this.apigClient = apigClientFactory.newClient(config);
    }

    getAuthHeaders() {
        const session = this.auth.getSignInUserSession();
        return {
            headers: {
                Authorization: session.getIdToken().getJwtToken(),
                "Content-Type": "application/x-amz-json-1.1",
            },
        };
    }

    deleteSandbox(awsAccountId: string, userAlias: string, sandboxId: string) {
        const method = "POST";
        const requestBody = {
            awsAccountId,
            userAlias,
            sandboxId,
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi({}, DELETE_SANDBOX_PATH, method, opts, requestBody);
    }

    activateUser(awsAccountId: string, userAlias: string) {
        const method = "POST";
        const requestBody = {
            awsAccountId,
            userAlias,
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi({}, ACTIVATE_USER_PATH, method, opts, requestBody)
            .catch((err: any) => {
                console.error(`Failed to activate user: ${err.message}`);
                console.error(err);
                return err.response
            });

    }

    getAccountAndUserInfo(awsAccountId: string, userAlias: string) {
        const method = "GET";
        const pathParams = {
            userAlias: userAlias,
            awsAccountId: awsAccountId,
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi(pathParams, GET_ACCOUNT_AND_USER_INFO_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to fetch account and user info: ${err.message}`);
                return err.response
            });
    }

    listAccountsForUserAlias(userAlias: string) {
        const method = "GET";
        const pathParams = {
            userAlias: userAlias,
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi(pathParams, LIST_ACCOUNTS_FOR_USER_ALIAS_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to fetch accounts: ${err.message}`);
                return err.response
            });
    }
}
