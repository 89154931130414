import React from 'react';
import SideBar from '../../navigation/sidebar.js';
import { AppLayout } from '@amzn/awsui-components-react';
import SchedulesTable from './table/table.js';
import AppContext from "../../context/AppContext";
import { getSidebarItemFromPageStage } from "../home/home";

class Schedules extends React.Component {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                content={<SchedulesTable />}
                navigation={<SideBar activeHref="/schedules" items={getSidebarItemFromPageStage(this.context.pageStage)} />}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default Schedules;