import React from "react";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { SandboxManagementClient } from "../client/sandbox-management-client";
import { MODSWorkflowManagementClient } from "../client/mods-workflow-management-client";
import { PageStage } from "../constants/constants";

export interface AppContextInterface {
    auth: CognitoAuth;
    sandboxManagementClient: SandboxManagementClient;
    modsWorkflowManagementClient: MODSWorkflowManagementClient;
    pageStage: PageStage;
}

const AppContext = React.createContext<AppContextInterface>(undefined);

export default AppContext;
