import React from "react";
import { Redirect } from "react-router-dom";
import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
    PROPERTY_FILTERING_OPTIONS,
    PROPERTY_FILTERING_I18N_CONSTANTS,
} from "./table-config";
import {
    Flashbar,
    Table,
    TableContentSelector,
    TablePropertyFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines,
} from "@amzn/awsui-components-react";
import { DistributionsHeader } from "./table-header.js";
import { TableNoMatchState } from "./components.js";
import { getApiUrl, verify, getOptions } from "../../../auth/login.js";
import * as sandbox_constants from "../../../constants/constants";

import "./table.css";
import AppContext from "../../../context/AppContext";

/**
 * Refresh interval for the sandbox in seconds
 *
 * @type {number}
 */
const SANDBOX_TABLE_REFRESH_INTERVAL = 300;

class ApiCaller {
    constructor(showAlert, showSuccess, refresh) {
        this.showAlert = showAlert;
        this.showSuccess = showSuccess;
        this.refresh = refresh;
    }

    getData(callback) {
        verify(async () => {
            const opts = await getOptions();
            opts.method = "GET";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            fetch(`${await getApiUrl()}/list_sandboxes?user_alias=${user}&aws_account_id=${account}`, opts)
                .then((res) => {
                    if (res.status === 500) {
                        this.showAlert("There is an internal server error. Please try again later.");
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data.sandboxInfos === undefined) {
                        callback([]);
                    } else {
                        callback(data.sandboxInfos);
                    }
                })
                .catch(console.log);
        });
    }

    postData(api, selected, callback) {
        let successMessage = "";
        let errorMessage = "";
        switch (successMessage) {
            case "start_sandbox":
                successMessage = "Sandbox is starting.";
                errorMessage = "There was a problem with starting your sandbox. Please try again later.";
                break;
            case "stop_sandbox":
                successMessage = "Sandbox is stopping.";
                errorMessage = "There was a problem with stopping your sandbox. Please try again later.";
                break;
            case "delete_sandbox":
                successMessage = "Sandbox is being deleted.";
                errorMessage = "There was a problem with deleting your sandbox. Please try again later.";
                break;
            default:
                successMessage = "Success";
                errorMessage = "There was a problem. Please try again later.";
        }
        verify(async () => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxId: selected.sandboxId,
            });
            opts.body = body;
            fetch((await getApiUrl()) + `/${api}`, opts)
                .then((res) => {
                    if (res.ok) {
                        this.refresh();
                        this.showSuccess(successMessage);
                    } else {
                        // 500 Error does not return an error message
                        if (res.status === 500) {
                            this.showAlert(errorMessage);
                        } else {
                            return res.json();
                        }
                    }
                })
                .then((resBody) => {
                    if (resBody === undefined) {
                        return;
                    }
                    if (resBody.message !== undefined && resBody.message !== null) {
                        this.showAlert(resBody.message);
                    } else {
                        this.showAlert(errorMessage);
                    }
                })
                .catch(this.showAlert(errorMessage))
                .then(() => {
                    if (callback) {
                        callback();
                    }
                });
        });
    }
}

const paginationLabels = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};

const distributionSelectionLabels = {
    itemSelectionLabel: (data, row) => `select ${row.id}`,
    allItemsSelectionLabel: () => "select all",
    selectionGroupLabel: "Distribution selection",
};

const generateColumnLabel =
    ({ id, header }) =>
    (sortState) => {
        const columnIsSorted = sortState.sortingColumn === id;
        const ascending = !sortState.sortingDescending;
        return `${typeof header === "function" ? header() : header}, ${
            columnIsSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
        }.`;
    };

const addColumnSortLabels = (columns, sortColumns) =>
    columns.map((col) => ({
        label: sortColumns.find((sortCol) => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
        ...col,
    }));

const columnDefinitions = addColumnSortLabels(COLUMN_DEFINITIONS, SORTABLE_COLUMNS);

export const getFilterCounterText = (count) => `${count} ${count === 1 ? "match" : "matches"}`;

class SandboxTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedSandboxes: [],
            sandboxes: props.sandboxes ? props.sandboxes : [],
            loading: true,
            filteringText: "",
            filteringTokens: [],
            filteringOptions: PROPERTY_FILTERING_OPTIONS,
            pageSize: 30,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            wrapLines: false,
            redirect: false,
            link: "",
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
            showDeleteModal: false,
            deleteConfirmChecked: false,
        };

        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.startSandbox = this.startSandbox.bind(this);
        this.stopSandbox = this.stopSandbox.bind(this);
        this.retryUpdatingFailedSandbox = this.retryUpdatingFailedSandbox.bind(this);
        this.updateSandbox = this.updateSandbox.bind(this);
        this.deleteSandbox = this.deleteSandbox.bind(this);
        this.getEndpoint = this.getEndpoint.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshButton = this.refreshButton.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.clearFlashbar = this.clearFlashbar.bind(this);
        this.redirectHome = this.redirectHome.bind(this);

        this.apiCaller = new ApiCaller(this.showAlert, this.showSuccess, this.refresh);
    }

    redirectHome() {
        this.setState({
            redirect: true,
            link: "/",
        });
    }

    onPaginationChange({ detail }) {
        this.setState({
            pageSize: detail.pageSize,
        });
    }

    onContentSelectionChange({ detail }) {
        const contentSelection = detail.contentSelection;
        const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
        this.setState({
            contentSelectorOptions: [
                {
                    label: currentContentSelectorOptionGroup.label,
                    options: currentContentSelectorOptionGroup.options.map((opt) => ({
                        id: opt.id,
                        label: opt.label,
                        editable: opt.editable,
                        visible: contentSelection.indexOf(opt.id) !== -1,
                    })),
                },
            ],
        });
    }

    onWrapLinesChange({ detail }) {
        this.setState({
            wrapLines: detail.value,
        });
    }

    onSelectionChange({ detail }) {
        this.setState({
            selectedSandboxes: detail.selectedItems,
        });
    }

    onPropertyFilteringChange({ detail }) {
        this.setState({
            filteringTokens: detail.tokens,
        });
    }

    formatData(sandboxes) {
        let result = [];
        for (let i = 0; i < sandboxes.length; i++) {
            let curr = sandboxes[i];
            let newBox = {};
            newBox.sandboxId = curr.sandboxId;
            newBox["status"] = curr["sandboxStatus"];
            newBox.type = curr.sandboxProperties.sandboxInstanceType;
            newBox.platform = curr.sandboxPlatformId;
            newBox.size = curr.sandboxProperties.diskSizeInGB;
            newBox.idleTime = curr.sandboxLifeCycleConfig.idleTimeToStopInMin;
            newBox.idleThreshold = curr.sandboxLifeCycleConfig.idleCPUThreshold;
            newBox.statusReason = curr.sandboxStatusReason;
            newBox.endpoint = this.getEndpoint;
            result.push(newBox);
        }
        return result;
    }

    componentDidMount() {
        this.refresh();
        this.interval = setInterval(() => this.refresh(false), SANDBOX_TABLE_REFRESH_INTERVAL * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    clearFilter() {
        this.setState({
            filteringText: "",
            filteringTokens: [],
        });
    }

    /**
     * Refresh the sandbox table data
     *
     * @param pageJustLoaded Whether or not the page just loaded.
     *   If true, the spinner will show.
     *   If false, the table will just refresh without displaying the spinner, replacing the old data when ready.
     */
    refresh(pageJustLoaded = true) {
        if (pageJustLoaded && this.state.loading === false) {
            this.setState({ loading: true });
        }
        if (!localStorage.getItem("userId") || !localStorage.getItem("account")) {
            this.redirectHome();
            return;
        }
        this.apiCaller.getData((sandboxes) => {
            sandboxes = sandboxes === undefined ? [] : this.formatData(sandboxes);
            sandboxes = sandboxes.filter(
                (sandbox) =>
                    sandbox_constants.stringToSandboxStatus(sandbox.status) !== sandbox_constants.SANDBOX_STATUS.DELETED
            );
            const filteringOptions = PROPERTY_FILTERING_OPTIONS.map((filteringOption) => {
                filteringOption.values = sandboxes.map((sandbox) => sandbox[filteringOption.propertyKey]);
                return filteringOption;
            });

            this.setState({
                sandboxes,
                filteringOptions,
                loading: false,
            });
        });
    }

    refreshButton() {
        this.clearFlashbar();
        this.refresh();
    }

    startSandbox() {
        const selected = this.state.selectedSandboxes;
        const succeeded = [];
        const failed = [];
        const sandbox_id_to_response_message = new Map();
        const callback = async (selected, secondCallback) => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxId: selected.sandboxId,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/start_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        succeeded.push(selected.sandboxId);
                    } else {
                        failed.push(selected.sandboxId);
                    }
                    return res.json();
                })
                .then((resJson) => {
                    sandbox_id_to_response_message.set(selected.sandboxId, resJson.message);
                })
                .catch(alert)
                .then(() => {
                    if (secondCallback) {
                        secondCallback();
                    }
                });
        };
        let prevCallback = () => {
            if (succeeded.length > 0) {
                this.showSuccess(
                    `The following sandboxes are being started: ${succeeded.reduce(
                        (acc, curr) => acc + curr + " ",
                        ""
                    )}`
                );
            }
            if(failed.length > 0){
                let alarm_msg = "Your request was not completed: "
                failed.forEach((failed_sandbox_id, i) => {
                    const message = sandbox_id_to_response_message.get(failed_sandbox_id)
                    alarm_msg = alarm_msg + `sandbox [${failed_sandbox_id}] failed to started due to: [${message}]. `
                });
                this.showAlert(alarm_msg);
            }
            this.refresh();
        };
        let firstBind = this;
        let atLeastOne = false;
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].status === "Stopped") {
                prevCallback = callback.bind(firstBind, selected[i], prevCallback);
                firstBind = null;
                atLeastOne = true;
            }
        }
        if (atLeastOne) {
            this.setState({
                loading: true,
            });
        }
        verify(prevCallback);
    }

    stopSandbox() {
        const selected = this.state.selectedSandboxes;
        const succeeded = [];
        const callback = async (selected, secondCallback) => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxId: selected.sandboxId,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/stop_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        succeeded.push(selected.sandboxId);
                    }
                    return res.json();
                })
                .catch(alert)
                .then(() => {
                    if (secondCallback) {
                        secondCallback();
                    }
                });
        };
        let prevCallback = () => {
            if (succeeded.length > 0) {
                this.showSuccess(
                    `The following sandboxes are being stopped: ${succeeded.reduce(
                        (acc, curr) => acc + curr + " ",
                        ""
                    )}`
                );
            } else {
                this.showAlert("Your request was not completed. Make sure the sandboxes are started.");
            }
            this.refresh();
        };
        let firstBind = this;
        let atLeastOne = false;
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].status === "InService") {
                prevCallback = callback.bind(firstBind, selected[i], prevCallback);
                firstBind = null;
                atLeastOne = true;
            }
        }
        if (atLeastOne) {
            this.setState({
                loading: true,
            });
        }
        verify(prevCallback);
    }

    retryUpdatingFailedSandbox() {
        const selected = this.state.selectedSandboxes;
        const succeeded = [];
        const callback = async (selected, secondCallback) => {
            const opts = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            const sandboxConfig = {
                sandboxId: selected.sandboxId,
                sandboxProperties: {
                    diskSizeInGB: selected.size,
                    sandboxInstanceType: selected.type,
                },
                sandboxLifeCycleConfig: {
                    idleTimeToStopInMin: selected.idleTime,
                    idleCPUThreshold: selected.idleThreshold,
                },
            };
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxInfo: sandboxConfig,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/update_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        succeeded.push(selected.sandboxId);
                    }
                    return res.json();
                })
                .catch(alert)
                .then(() => {
                    if (secondCallback) {
                        secondCallback();
                    }
                });
        };
        let prevCallback = () => {
            if (succeeded.length > 0) {
                this.showSuccess(
                    `The following failed sandbox updates are being retried: ${succeeded.reduce(
                        (acc, curr) => acc + curr + " ",
                        ""
                    )}`
                );
            } else {
                this.showAlert("Your request was not completed. Please make sure the last sandbox update failed before you retry.");
            }
            this.refresh();
        };
        let firstBind = this;
        let atLeastOne = false;
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].status === "Failed") {
                prevCallback = callback.bind(firstBind, selected[i], prevCallback);
                firstBind = null;
                atLeastOne = true;
            }
        }
        if (atLeastOne) {
            this.setState({
                loading: true,
            });
        }
        verify(prevCallback);
    }

    updateSandbox() {
        const selected = this.state.selectedSandboxes[0];
        if (this.state.selectedSandboxes.length > 1) {
            alert("Only one sandbox can be edited at a time.");
        }
        this.setState({ redirect: true, link: "/update/" + selected.sandboxId });
    }

    deleteSandbox() {
        const account = localStorage.getItem("account");
        const user = localStorage.getItem("userId");
        if (this.state.selectedSandboxes.length === 0) {
            return;
        }

        this.setState({ loading: true });
        const selectedId = this.state.selectedSandboxes[0].sandboxId;
        const failureMessage = (
            <p>
                Failed to delete sandbox {selectedId}.{" "}
                <a href={sandbox_constants.TROUBLESHOOTING_SIM_LINK}>Click to cut a troubleshooting SIM.</a>
            </p>
        );

        this.context.sandboxManagementClient
            .deleteSandbox(account, user, selectedId)
            .then(() => {
                this.refresh();
                this.showSuccess(`Deleting sandbox ${selectedId}`);
            })
            .catch(() => {
                this.refresh();
                this.showAlert(failureMessage);
            });
    }

    getEndpoint(sandbox, jupyterlab = true) {
        verify(async () => {
            const opts = await getOptions();
            opts.method = "GET";
            const user = localStorage.getItem("userId");
            const account = localStorage.getItem("account");
            fetch(
                `${await getApiUrl()}/get_sandbox_endpoint_url?user_alias=${user}&aws_account_id=${account}&sandbox_id=${sandbox}`,
                opts
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        const errorMessage = { message: "Endpoint cannot be reached", code: 402 };
                        throw errorMessage;
                    }
                })
                .then(
                    (data) => {
                        window.open(`${data.signedSandboxEndpointUrl}${jupyterlab ? "&view=lab" : ""}`);
                    },
                    (e) => {
                        this.showAlert(e.message);
                    }
                )
                .catch(console.log);
        });
    }

    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
        });
    }

    showSuccess(message) {
        this.setState({
            showSuccess: true,
            success: message,
        });
    }

    clearFlashbar() {
        this.setState({
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.link} />;
        }
        return (
            <div>
                <h1>My Sandboxes</h1>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    dismiss: this.clearFlashbar,
                                },
                            ]}
                        />
                    </div>
                ) : null}

                <Table
                    columnDefinitions={columnDefinitions}
                    items={this.state.sandboxes}
                    stickyHeader={true}
                    resizableColumns={true}
                    header={
                        <div>
                            <DistributionsHeader
                                selectedItems={this.state.selectedSandboxes}
                                totalItems={this.state.sandboxes}
                                updateTools={this.props.updateTools}
                                refresh={this.refreshButton}
                                startSandbox={this.startSandbox}
                                stopSandbox={this.stopSandbox}
                                updateSandbox={this.updateSandbox}
                                deleteSandbox={this.deleteSandbox}
                                retryUpdatingFailedSandbox={this.retryUpdatingFailedSandbox}
                            />
                        </div>
                    }
                    loading={this.state.loading}
                    noMatch={<TableNoMatchState onClearFilter={this.clearFilter.bind(this)} />}
                    wrapLines={this.state.wrapLines}
                    onWrapLinesChange={this.onWrapLinesChange.bind(this)}
                >
                    <TablePropertyFiltering
                        {...PROPERTY_FILTERING_I18N_CONSTANTS}
                        removeTokenButtonLabel={({ propertyLabel, value }) =>
                            `Remove filtering token for ${propertyLabel} with value ${value}`
                        }
                        filteringText={this.state.filteringText}
                        filteringOptions={this.state.filteringOptions}
                        tokens={this.state.filteringTokens}
                        onPropertyFilteringChange={this.onPropertyFilteringChange.bind(this)}
                        allowFreeTextFiltering={true}
                        filteringCountTextFunction={getFilterCounterText}
                    />
                    <TablePagination
                        onPaginationChange={this.onPaginationChange}
                        labels={paginationLabels}
                        pageSize={this.state.pageSize}
                    />
                    <TableSorting sortableColumns={SORTABLE_COLUMNS} />
                    <TableSelection
                        trackBy="sandboxId"
                        selectedItems={this.state.selectedSandboxes}
                        labels={distributionSelectionLabels}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector
                            title="Select visible columns"
                            options={this.state.contentSelectorOptions}
                        />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}

export default SandboxTable;
