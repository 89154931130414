import React from "react";
import {
    AppLayout,
    Button,
    Flashbar
} from "@amzn/awsui-components-react";
import SideBar from "../../navigation/sidebar.js";
import InfoTable from "./table/table";
import AppContext from "../../context/AppContext";
import {INTERNAL_ERROR_MESSAGE, PageStage, stringToUserStatus, USER_STATUS} from "../../constants/constants";
import {Redirect} from "react-router-dom";
import {getSidebarItemFromPageStage} from "../home/home"

class AccountForm extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            accountInfoLoaded: false,
            showErrorMsg: false,
            errorMsg: "",
            showActivateButton: false, // Show Activate button when user is currently under "Unknown", "Inactive"
            // and "Activating" status
            disableActivateButton: true, // Disable the activate button when user clicked the button or under
            // "Activating" status
            ActivateUserButtonMsg: "Activate My Access to the Account"
        };

        // Reference to information table so we can update the content later
        this.accountInfoTableRef = React.createRef();
        this.userInfoTableRef = React.createRef();

        this.getAccountDetails = this.getAccountDetails.bind(this);
        this.activateUser = this.activateUser.bind(this);
    }

    // <----------------------- Alert Message For All Type Of Error UI Components -----------------------> //
    showAlert(message) {
        this.setState({
            showErrorMsg: true,
            errorMsg: message,
        });
    }

    resetAlert() {
        this.setState({
            showErrorMsg: false,
            errorMsg: "",
        });
    }

    // <--------------------------- Get Account And User Info UI Components ---------------------------> //
    resetAccountInfoToLoading() {
        this.accountInfoTableRef.current.cleanAndSetToLoading();
    }

    setAccountInfoContent(accountInfoToDisplay) {
        this.accountInfoTableRef.current.setTableContent(accountInfoToDisplay);
    }

    resetUserInfoToLoading() {
        this.userInfoTableRef.current.cleanAndSetToLoading();
    }

    setUserInfoContent(userInfoToDisplay) {
        this.userInfoTableRef.current.setTableContent(userInfoToDisplay);
    }

    // <--------------------------------- Activate User UI Components ---------------------------------> //
    showActivateButton() {
        this.setState({
            showActivateButton: true,
        });
    }

    hideActivateButton() {
        this.setState({
            showActivateButton: false,
        });
    }

    disableActivateButton() {
        this.setState({
            disableActivateButton: true,
        });
    }

    enableActivateButton() {
        this.setState({
            disableActivateButton: false,
        });
    }

    setActivateButtonText(buttonText) {
        this.setState({
            ActivateUserButtonMsg: buttonText,
        });
    }

    resetActivateButtonText() {
        this.setState({
            ActivateUserButtonMsg: "Activate My Access to the Account",
        });
    }

    // <---------------------------- Get Account And User Info API Call ----------------------------> //
    getAccountDetails() {
        const user = localStorage.getItem("userId");
        const accountId = localStorage.getItem("account");

        // Clean up table content
        this.resetAccountInfoToLoading();
        this.resetUserInfoToLoading();

        this.context.sandboxManagementClient
            .getAccountAndUserInfo(accountId, user)
            .then((res) => {
                if (res === undefined) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE);
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve account and user information: ${res.data.message}`);
                } else {
                    const retrievedAccountInfo = res.data.awsAccountInfo;
                    const retrievedUserInfo = res.data.userInfo;
                    const retrievedUserStatus = stringToUserStatus(retrievedUserInfo.userStatus)

                    // Reset the alert message
                    this.resetAlert();

                    // Update UI components
                    this.setAccountInfoContent(retrievedAccountInfo);
                    this.setUserInfoContent(retrievedUserInfo);

                    if (retrievedUserStatus === USER_STATUS.UNKNOWN || retrievedUserStatus === USER_STATUS.INACTIVE) {
                        this.showActivateButton();
                        this.enableActivateButton();
                        this.resetActivateButtonText();
                    } else if (retrievedUserStatus === USER_STATUS.ACTIVATING) {
                        this.showActivateButton();
                        this.setActivateButtonText("Activating your access now. Please check back later.")
                        this.disableActivateButton();
                    } else if (retrievedUserStatus === USER_STATUS.ACTIVE) {
                        this.hideActivateButton();
                        this.disableActivateButton();
                    } else {
                        // Other status should not happen for now but disable the activate button anyway to avoid
                        // unexpected user operation
                        this.hideActivateButton();
                        this.disableActivateButton();
                    }
                }
            })
            .catch((error) => {
                console.error(`Unexpected exception happened when retrieve user and account information: ${error}`);
                this.showAlert(INTERNAL_ERROR_MESSAGE);
            });

        this.setState({accountInfoLoaded: true});
        const intervalToNextTry = 15 * 60 * 1000;

        // call getAccountDetails() again in internalToNextTry ms to refresh table content
        console.info(`Wait for ${intervalToNextTry} ms for next account check.`);
        this.tableRefreshIntervalID = setTimeout(this.getAccountDetails.bind(this), intervalToNextTry);
    }

    // <-------------------------------- Activate User API Call --------------------------------> //
    activateUser() {
        // Disable activating button first.
        this.disableActivateButton();
        this.resetActivateButtonText();
        this.resetAlert();
        this.setActivateButtonText("Requesting activate your access...")

        const user = localStorage.getItem("userId");
        const account = localStorage.getItem("account");

        this.context.sandboxManagementClient
            .activateUser(account, user)
            .then((res) => {
                console.info(res);
                if (res === undefined) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE)
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to activate user with error: ${res.data.message}`)
                    this.resetActivateButtonText();
                } else {
                    this.setActivateButtonText("Activating your access now. Please check back later.")
                    this.disableActivateButton();
                    this.getAccountDetails()
                }
            })
            .catch((error) => {
                console.error(`Unexpected exception happened when activate user: ${error}`);
                this.resetActivateButtonText();
                this.showAlert(INTERNAL_ERROR_MESSAGE);
            });
    }

    async componentDidMount() {
        console.debug("Account page mounted.");
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.accountInfoLoaded && !this.state.accountInfoLoaded) {
                console.debug("Page fully loaded and account never being loaded. Retrieve account list from remote.");
                this.getAccountDetails();
            }
        }
    }

    async componentWillUnmount() {
        // stop getAccountDetails() from continuing to run even after unmounting this component.
        clearTimeout(this.tableRefreshIntervalID);
    }

    render() {
        if (!localStorage.getItem("account") || !localStorage.getItem("userId")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div>
                <div className="col-xxs-10">
                    { this.state.showErrorMsg &&
                        <div>
                            <Flashbar items={[{type: "error", content: this.state.errorMsg, dismissible: true}]}/>
                        </div>
                    }
                    <div className="awsui-util-container">
                        <div className="awsui-text-medium">
                            <h2 className="awsui-text-medium">Current Account Info</h2>
                            <InfoTable ref={this.accountInfoTableRef}/>
                        </div>
                    </div>
                    <div className="awsui-util-container">
                        <div className="awsui-text-medium">
                            <h2 className="awsui-text-medium">Current User Info</h2>
                            <InfoTable ref={this.userInfoTableRef}/>
                        </div>
                        <div>
                            { this.state.showActivateButton === true &&
                                <Button key={this.state.ActivateUserButtonMsg}
                                        onClick={this.activateUser} variant="primary"
                                        disabled={this.state.disableActivateButton}>
                                    {this.state.ActivateUserButtonMsg}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function Content() {
    return (
        <div id="AccountInfo">
            <h1>My Account</h1>
            <AccountForm />
        </div>
    )
};

class Account extends React.Component {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                content={<Content />}
                navigation={<SideBar activeHref="/account" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default Account;
