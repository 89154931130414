import React from "react";

import { AppLayout, Button, Flashbar, Form, FormField, Input, Select, SegmentedControl, Alert } from "@amzn/awsui-components-react";
import { Redirect } from "react-router-dom";

import SideBar from "../../navigation/sidebar.js";

import { verify, getOptions, getApiUrl } from "../../auth/login.js";
import { instanceTypes, instance_additional_gpu_info } from "../instance-types";

import "./create.css";
import {PLATFORM_IDS} from "../../constants/constants";
import {
    enforceSandboxIdleCpuUtilInput,
    enforceSandboxIdleTimeInput,
    enforceSandboxStorageInput,
} from "../../util/form-utils";
import AppContext from "../../context/AppContext";
import {getSidebarItemFromPageStage} from "../home/home"

export interface SandboxConfigProps {
    loading: boolean;
    setLoading: any;
    createSandbox: any;
}

export interface SandboxConfigState {
    configs: any;
    sandboxId: string;
    type: string;
    size: number;
    idleThreshold: number;
    idleTime: number;
    platform: string;
}

class SandboxConfig extends React.Component<SandboxConfigProps, SandboxConfigState> {
    state = {
        configs: instanceTypes,
        sandboxId: "",
        type: "ml.t3.large",
        size: 50,
        idleThreshold: 10,
        idleTime: 120,
        platform: PLATFORM_IDS.AL2
    };


    constructor(props: SandboxConfigProps) {
        super(props);

        this.create = this.create.bind(this);
    }

    create() {
        const newConfig = {
            sandboxId: this.state.sandboxId,
            sandboxStatus: "Unknown",
            sandboxPlatformId: this.state.platform,
            sandboxProperties: {
                diskSizeInGB: this.state.size,
                sandboxInstanceType: this.state.type,
            },
            sandboxLifeCycleConfig: {
                idleTimeToStopInMin: this.state.idleTime,
                idleCPUThreshold: this.state.idleThreshold,
            },
        };
        this.props.setLoading();
        this.props.createSandbox(newConfig);
    }

    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div className="awsui-util-container col-l-8 col-xl-7 col-m-9 col-s-12 col-xs-12 col-xxs-12">
                <div className="awsui-util-container-header">
                    <h2>Change Configuration</h2>
                </div>
                <div>
                    <FormField
                        label="Instance Type"
                        description={
                            <>
                                Provide the type of your instance. See{" "}
                                <a href="https://aws.amazon.com/sagemaker/pricing">SageMaker pricing</a> for more
                                hardware and pricing information.
                            </>
                        }
                    >
                        <Select
                            selectedLabel="Selected"
                            options={this.state.configs.type_groups}
                            selectedOption={{ id: this.state.type, label: this.state.type }}
                            empty="No options"
                            placeholder="Choose a type"
                            ariaRequired={true}
                            onChange={({ detail }) => {
                                this.setState({ type: detail.selectedOption.id  });
                            }}
                        />
                    </FormField>
                    <br/>




                    <label id="instanceMessage">The instance {this.state.type} offers {instance_additional_gpu_info(this.state.type)}
                    </label>
                    <br/>
                    <br/>
                    <div className="sandbox-form-text">
                        <div className="awsui-util-mb-s">
                            <Alert
                                header={
                                    <>
                                        Amazon SageMaker Notebook Instance has ended its standard support on Amazon Linux AMI (AL1).
                                        <a href="https://aws.amazon.com/blogs/machine-learning/amazon-sagemaker-notebook-instance-now-supports-amazon-linux-2/"
                                           target="_blank"> Learn More</a>
                                        <br />
                                        If you are using AL1 based sandboxes, we strongly recommend that you migrate to AL2 as soon as possible.<br />
                                        For guidance on backing up and migrating your sandboxes refer to our
                                        <a href="https://w.amazon.com/bin/view/CMLS/SecureAISandbox/Guides/AL2MigrationGuide/"
                                           target="_blank"> AL2 Migration Guide</a>.
                                    </>
                                }
                            />
                            <FormField
                                className="sandbox-form-text"
                                label="Platform Identifier"
                                description={
                                    <>
                                        The operating system your sandbox will be based on.
                                        <a href="https://docs.aws.amazon.com/sagemaker/latest/dg/nbi-al2.html"
                                           target="_blank"> Learn More</a>
                                        <br />
                                    </>
                                }
                            />
                        </div>
                        <SegmentedControl
                            selectedId={this.state.platform}
                            options={[
                                { text: "AL2", id: PLATFORM_IDS.AL2 }
                            ]}
                            onChange={({ detail }) => {
                                this.setState({ platform: detail.selectedId });
                            }}
                        />
                    </div>

                    <div className="sandbox-form-text">
                        <FormField label="Storage Size" description="Indicate the storage size of your instance in GB.">
                            <Input
                                type="number"
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxStorageInput(inputNumber, (value) => this.setState({ size: value }));
                                }}
                                value={this.state.size.toString()}
                            />
                        </FormField>
                    </div>
                    <div className="sandbox-form-text">
                        <FormField
                            label="Instance Uptime"
                            description="Provide how long your instance can be idle before automatically stopping in minutes."
                        >
                            <Input
                                type="number"
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxIdleTimeInput(inputNumber, (value) =>
                                        this.setState({ idleTime: value })
                                    );
                                }}
                                value={this.state.idleTime.toString()}
                            />
                        </FormField>
                    </div>
                    <div className="sandbox-form-text">
                        <FormField
                            label="Idle Threshold"
                            description="Designate the CPU usage threshold for this sandbox to be considered idle."
                        >
                            <Input
                                onChange={({ detail }) => {
                                    const inputNumber = parseInt(detail.value, 10);
                                    enforceSandboxIdleCpuUtilInput(inputNumber, (value) =>
                                        this.setState({ idleThreshold: value })
                                    );
                                }}
                                value={this.state.idleThreshold.toString()}
                                type="number"
                            />
                        </FormField>
                    </div>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <Button
                                id="update-button"
                                variant="primary"
                                disabled={false}
                                loading={this.props.loading}
                                onClick={this.create}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface CreateFormProps {}

interface CreateFormState {
    loading: boolean;
    showAlert: boolean;
    alert: string;
    showSuccess: boolean;
    success: string;
}

class CreateForm extends React.Component<CreateFormProps, CreateFormState> {
    state = {
        loading: false,
        showAlert: false,
        alert: "",
        showSuccess: false,
        success: "",
    };

    constructor(props: CreateFormProps) {
        super(props);

        this.createSandbox = this.createSandbox.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    createSandbox(newConfig: any) {
        console.log(`Creating sandbox with parameters ${JSON.stringify(newConfig)}`);
        if (newConfig === undefined) {
            this.showAlert("There was an error with the configuration. Please try again with a valid configuration.");
            return;
        } else if (newConfig.sandboxId === undefined) {
            this.showAlert("A valid sandbox was not selected. Please reselect a sandbox to update.");
            return;
        } else if (newConfig.sandboxProperties === undefined) {
            this.showAlert("There was an error with the properties of the sandbox.");
            return;
        } else if (
            newConfig.sandboxProperties.diskSizeInGB === undefined ||
            newConfig.sandboxProperties.diskSizeInGB < 1
        ) {
            this.showAlert("Please select a valid storage size (greater than 0).");
            return;
        } else if (newConfig.sandboxProperties.sandboxInstanceType === undefined) {
            this.showAlert("Please pick a valid instance type.");
            return;
        } else if (newConfig.sandboxLifeCycleConfig === undefined) {
            this.showAlert("There was an error with the configuration. Please try again with a valid configuration.");
            return;
        } else if (
            newConfig.sandboxLifeCycleConfig.idleTimeToStopInMin === undefined ||
            newConfig.sandboxLifeCycleConfig.idleTimeToStopInMin < 1
        ) {
            this.showAlert("Please select a valid idle time (greater than 0).");
            return;
        } else if (
            newConfig.sandboxLifeCycleConfig.idleCPUThreshold === undefined ||
            newConfig.sandboxLifeCycleConfig.idleCPUThreshold < 1
        ) {
            this.showAlert("Please select a valid idle threshold (greater than 0).");
            return;
        } else if (newConfig.sandboxPlatformId === undefined) {
            this.showAlert("There was an error with the platform identifier of the sandbox.");
            return;
        }
        verify(async () => {
            const opts: any = await getOptions();
            opts.method = "POST";
            const user = localStorage.getItem("userId");
            if (user === undefined) {
                this.showAlert(
                    "there was a problem verifying your user alias. Please try again or contact CMLS for support if the issue persists."
                );
            }
            const account = localStorage.getItem("account");
            const body = JSON.stringify({
                awsAccountId: account,
                userAlias: user,
                sandboxInfo: newConfig,
            });
            opts.body = body;
            fetch((await getApiUrl()) + "/create_sandbox", opts)
                .then((res) => {
                    if (res.ok) {
                        this.showSuccess("Your sandbox is being created.");
                    } else {
                        if (res.status === 500) {
                            this.showAlert("There was an error with creating your sandbox. Please try again later.");
                        } else {
                            return res.json();
                        }
                    }
                })
                .then((resBody) => {
                    if (resBody === undefined) {
                        this.setState({ loading: false });
                        return;
                    }
                    if (resBody.message !== undefined && resBody.message !== null) {
                        this.showAlert(resBody.message);
                    } else {
                        this.showAlert("There was an error with creating your sandbox. Please try again later.");
                    }
                    this.setState({ loading: false });
                })
                .catch(this.showAlert);
        });
    }

    showAlert(message: string) {
        this.setState({
            showAlert: true,
            alert: message,
            showSuccess: false,
            success: "",
        });
    }

    showSuccess(message: string) {
        this.setState({
            showSuccess: true,
            success: message,
            showAlert: false,
            alert: "",
        });
    }

    setLoading() {
        this.setState({
            loading: true,
        });
    }

    render() {
        if (!localStorage.getItem("account")) {
            return <Redirect to={"/"} />;
        }

        return (
            <div>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    dismiss: () =>
                                        this.setState({
                                            showAlert: false,
                                            alert: "",
                                            showSuccess: false,
                                            success: "",
                                        }),
                                },
                            ]}
                        />
                    </div>
                ) : null}

                <Form>
                    <SandboxConfig
                        loading={this.state.loading}
                        setLoading={this.setLoading}
                        createSandbox={this.createSandbox}
                    />
                </Form>
            </div>
        );
    }
}

const Content = () => (
    <div id="Create">
        <h1>Create Sandbox</h1>
        <CreateForm />
    </div>
);

class Create extends React.Component<any, any> {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                content={<Content />}
                navigation={<SideBar activeHref="/create" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default Create;
