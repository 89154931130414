import { Button, Checkbox, Modal } from "@amzn/awsui-components-react";
import React from "react";

export interface DeleteSandboxConfirmationModalProps {
    deleteSandbox: () => any;
    visible: boolean;
    setVisible: (visible: boolean) => any;
    sandboxId: string;
}

export interface DeleteSandboxConfirmationModalState {
    confirmed: boolean;
}

export class DeleteSandboxConfirmationModal extends React.Component<
    DeleteSandboxConfirmationModalProps,
    DeleteSandboxConfirmationModalState
> {
    state = {
        confirmed: false,
    };

    render() {
        return (
            <Modal
                onDismiss={() => {
                    this.props.setVisible(false);
                    this.setState({ confirmed: false });
                }}
                visible={this.props.visible}
                closeLabel="Close modal"
                size="medium"
                footer={
                    <div id="deleteSandboxConfirmation">
                        <Button variant="link" onClick={() => this.props.setVisible(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!this.state.confirmed}
                            variant="primary"
                            onClick={() => {
                                this.setState({ confirmed: false });
                                this.props.setVisible(false);
                                this.props.deleteSandbox();
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                }
                header="Delete Confirmation: Backup Data"
            >
                <p>
                    While deleting sandbox <b>{this.props.sandboxId}</b> will not remove data from personal or team s3
                    buckets, all data on the Sandbox's disk will be gone forever. Please confirm that you have backed up
                    any data you may need.
                </p>

                <p>
                    Please see{" "}
                    <a href="https://w.amazon.com/bin/view/CMLS/SecureAISandbox/Guides/BackupYourWorkspace/">
                        our workspace backup guidance
                    </a>{" "}
                    for help backing up your workspace.
                </p>

                <Checkbox
                    onChange={() => this.setState({ confirmed: !this.state.confirmed })}
                    checked={this.state.confirmed}
                >
                    I have backed up all necessary data.
                </Checkbox>
            </Modal>
        );
    }
}
