import React from "react";
import { Button, Icon } from "@amzn/awsui-components-react";
import { TableHeader } from "./components";
import { WORKFLOW_EXECUTION_STATUS, stringToWorkflowExecutionStatus } from "../../../constants/constants";

export const DistributionsHeader = ({ ...props }) => {
    const selected = props.selectedItems;

    let running = false;
    let failed = false;
    for (let i = 0; i < selected.length; i++) {
        const curr = selected[i];
        const currStatus = stringToWorkflowExecutionStatus(curr.executionStatus);

        if (!running) {
            running = currStatus === WORKFLOW_EXECUTION_STATUS.RUNNING;
        }
        if (!failed) {
            failed = currStatus === WORKFLOW_EXECUTION_STATUS.FAILED;
        }
    }

    return (
        <>
            <TableHeader
                {...props}
                title="Workflow Executions"
                actionButtons={
                    <div>
                        <Button onClick={props.refresh}>
                            <Icon name="refresh" />
                        </Button>
                        <Button disabled={!failed} onClick={props.retryFailedExecution}>
                            Retry
                        </Button>
                        <Button disabled={!running} onClick={props.cancelExecution}>
                            Cancel
                        </Button>
                    </div>
                }
            />
        </>
    );
};
