import {
    MAX_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE,
    MAX_SANDBOX_IDLE_TIME_SETTING_IN_MIN,
    MAX_SANDBOX_VOLUME_SIZE_IN_GB,
    MIN_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE,
    MIN_SANDBOX_IDLE_TIME_SETTING_IN_MIN,
    MIN_SANDBOX_VOLUME_SIZE_IN_GB,
} from "../constants/constants";

export type NumericalValueSetter = (value: number) => any;

/**
 * Force the input value inside an input field to be within certain bounds
 *
 * @param inputValue
 * @param minValue
 * @param maxValue
 * @param setValue The setter that sets the component state and/or field value.
 * @returns {number} The input value within the trimmed range.
 */
export function enforceInputBounds(
    inputValue: number,
    minValue: number,
    maxValue: number,
    setValue: NumericalValueSetter
): number {
    const boundedValue = Math.min(maxValue, Math.max(minValue, inputValue));
    setValue(boundedValue);
    return boundedValue;
}

/**
 * Force the input value inside the sandbox storage to be within the allowed values
 *
 * @param inputValue
 * @param setValue The setter that sets the component state and/or field value.
 * @returns {number} The input storage trimmed to the allowed range.
 */
export function enforceSandboxStorageInput(inputValue: number, setValue: NumericalValueSetter): number {
    return enforceInputBounds(inputValue, MIN_SANDBOX_VOLUME_SIZE_IN_GB, MAX_SANDBOX_VOLUME_SIZE_IN_GB, setValue);
}

/**
 * Force the input value inside the sandbox idle time input to be within allowed values
 *
 * @param inputValue
 * @param setValue The setter that sets the component state and/or field value.
 * @returns {number} The input cpu idle threshold trimmed to the allowed range.
 */
export function enforceSandboxIdleTimeInput(inputValue: number, setValue: NumericalValueSetter): number {
    return enforceInputBounds(
        inputValue,
        MIN_SANDBOX_IDLE_TIME_SETTING_IN_MIN,
        MAX_SANDBOX_IDLE_TIME_SETTING_IN_MIN,
        setValue
    );
}

/**
 * Force the input value inside the sandbox cpu utilization threshold to be within allowed values
 *
 * @param inputValue
 * @param setValue The setter that sets the component state and/or field value.
 * @returns {number} The input to the cpu idle utilization field trimmed to the allowed range.
 */
export function enforceSandboxIdleCpuUtilInput(inputValue: number, setValue: NumericalValueSetter): number {
    return enforceInputBounds(
        inputValue,
        MIN_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE,
        MAX_SANDBOX_IDLE_CPU_UTIL_PERCENTAGE,
        setValue
    );
}
