import React from "react";
import {StatusIndicatorProps} from "@amzn/awsui-components-react-v3";

export function getStatusIndicator(status: string): StatusIndicatorProps.Type {
    let statusType;
    switch (status) {
        case "RUNNING":
            statusType = "loading";
            break;
        case "COMPLETE":
            statusType = "success";
            break;
        case "FAILED":
            statusType = "error";
            break;
        case "VALIDATING":
            statusType = "loading";
            break;
        case "VALIDATED":
            statusType = "success";
            break;
        case "PROVISIONING":
            statusType = "loading";
            break;
        case "PROVISIONED":
            statusType = "positive";
            break;
        case "STARTING":
            statusType = "pending";
            break;
        case "STOPPING":
            statusType = "loading";
            break;
        case "BLOCKED":
            statusType = "error";
            break;
        default:
            statusType = "info";
            break;
    }

    return statusType;
}
