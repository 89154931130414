import { Header, SpaceBetween,  StatusIndicator, Container, Box, ColumnLayout, ContentLayout} from "@amzn/awsui-components-react-v3";
import AppContext from "../../../context/AppContext";
import React from "react";
import {INTERNAL_ERROR_MESSAGE, PageStage} from "../../../constants/constants";
import {getStatusIndicator} from "./detail-table-config";
import {Button, ButtonDropdown, Flashbar, Textarea} from "@amzn/awsui-components-react";


export const PageHeader = ({ header, buttons }) => {
    return (
        <Header
            variant="h1"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    {buttons.map((button, key) =>
                        !button.items ? (
                            <Button href={button.href || ''} disabled={button.disabled || false} key={key}>
                                {button.text}
                            </Button>
                        ) : (
                            <ButtonDropdown items={button.items} key={key}>
                                {button.text}
                            </ButtonDropdown>
                        )
                    )}
                </SpaceBetween>
            }
        >
            {header}
        </Header>
    );
};


class ConfigTable extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            generalData: {},
            generalDataLoaded: false,
            infoLoaded: false,
            showErrorMsg: false,
            errorMsg: "",
            gitfarmPackage: "",
            templateName: "",
            templateVersion: "",
            executionId: "",
            umlcPageUrl: "",
            requester: "",
            executionStatus: "",
            executionStructure: "",
            executionDocumentName: "",
            executionDocumentVersion: ""
        };

        this.getConfigData = this.getConfigData.bind(this);
        this.getGeneralData = this.getGeneralData.bind(this);

    }

    resetContentToLoading(ref) {
        ref.cleanAndSetToLoading();
    }

    setInfoContent(ref, contentToDisplay) {
        ref.setTableContent(contentToDisplay);
    }

     getGeneralData() {
         const currentLocationPath = window.location.pathname.split("/");
         const id = currentLocationPath[2];
         const pathQueries = {
             execution_aws_account_id: localStorage.getItem("modsAccount")
         }
         this.context.modsWorkflowManagementClient.getWorkflowExecutionDetail(pathQueries, id)
             .then((res) => {
                 if (res.status === 500) {
                     this.showAlert("There is an internal server error. Please try again later.");
                 }
                 return res.data;
             })
             .then((data) => {
                 this.setState({
                     generalData: data.workflowExecutionInfo,
                     generalDataLoaded: true,
                     gitfarmPackage: data.workflowExecutionInfo.gitfarmPackage,
                     templateName: data.workflowExecutionInfo.templateName,
                     templateVersion: data.workflowExecutionInfo.templateVersion,
                     executionId: data.workflowExecutionInfo.executionId,
                     umlcPageUrl: data.workflowExecutionInfo.umlcPageUrl,
                     requester: data.workflowExecutionInfo.requester,
                     executionStatus: data.workflowExecutionInfo.executionStatus,
                     executionDocumentName: data.workflowExecutionInfo.executionDocumentName,
                     executionDocumentVersion: data.workflowExecutionInfo.executionDocumentVersion
                 })
             })
             .catch(console.log);
     };

    getConfigData() {
        const pathQueries = {
            concrete_execution_document_name: this.state.executionDocumentName,
            concrete_execution_document_version: this.state.executionDocumentVersion,
            gitfarm_package: this.state.gitfarmPackage,
            template_name:this.state.templateName,
            template_version: this.state.templateVersion
        }

        this.context.modsWorkflowManagementClient
            .getConcreteExecutionDocument(pathQueries)
            .then((res) => {
                if (res === undefined) {
                    this.showAlert(INTERNAL_ERROR_MESSAGE);
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve workflow execution detail information: ${res.data.message}`);
                } else {
                    const url = res.data.concreteExecutionDocumentInfo.contentPresignedUrl;
                    fetch(url, {method: 'GET'})
                        .then(res => res.json())
                        .then(data => {
                            this.setState({
                                executionStructure: JSON.stringify(data,null, '  ')
                            })
                        })
                        .catch(error => console.log('Request failed', error));
                }
            })
            .catch((error) => {
                console.error(`Unexpected exception happened when retrieving information: ${error}`);
                this.showAlert(INTERNAL_ERROR_MESSAGE);
            });

        this.setState({infoLoaded: true});
    }

    showAlert(message) {
        this.setState({
            showErrorMsg: true,
            errorMsg: message,
        });
    }

    resetAlert() {
        this.setState({
            showErrorMsg: false,
            errorMsg: "",
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.generalDataLoaded && !this.state.generalDataLoaded) {
                console.debug("Page fully loaded and execution detail never being loaded. Retrieve execution detail from remote.");
                this.getGeneralData();
            }
        }
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.infoLoaded && !this.state.infoLoaded && this.state.templateName) {
                console.debug("Page fully loaded and execution config never being loaded. Retrieve execution detail from remote.");
                this.getConfigData();
            }
        }

    }

    render() {
        return (
            <div className="col-xxs-10">
                { this.state.showErrorMsg &&
                    <div>
                        <Flashbar items={[{type: "error", content: this.state.errorMsg, dismissible: true}]}/>
                    </div>
                }
                <ContentLayout
                        header={
                            <PageHeader
                                header = {this.state.executionId}
                                buttons={[{ text: 'Link to UMLC', href: this.state.umlcPageUrl }]}
                            />
                        }
                    >
                    <SpaceBetween size="l">
                        <Container header={<Header variant="h2">General Information</Header>}>
                            <ColumnLayout columns={4} variant="text-grid">
                                <div>
                                    <Box variant="awsui-key-label">Workflow Definition Being Used</Box>
                                    <div>{this.state.templateName}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Workflow Execution Status</Box>
                                    <StatusIndicator type={getStatusIndicator(this.state.executionStatus)}>{this.state.executionStatus}</StatusIndicator>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Workflow Execution Requester</Box>
                                    <div>{this.state.requester}</div>
                                </div>
                            </ColumnLayout>
                        </Container>
                        <Container header={<Header variant="h2">Other Step Configuration</Header>}>
                            <Textarea
                                onChange={({ detail }) => setValue(detail.value)}
                                rows={this.state.executionStructure.split(/\r\n|\r|\n/).length}
                                value={this.state.executionStructure}
                                readOnly
                            />
                        </Container>
                    </SpaceBetween>
                </ContentLayout>
            </div>
        );
    }
}
export default ConfigTable;