import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";

import "@amzn/awsui-components-react/index.css";

ReactDOM.render(
  <div className="awsui">
    <App />
  </div>,
  document.getElementById("root")
);
