import React from "react";
import { Button, Icon, Popover } from "@amzn/awsui-components-react";
import { SANDBOX_STATUS, stringToSandboxStatus } from "../../../constants/constants";

function getStatusClass(status: SANDBOX_STATUS, error_msg: string): string {
    const statusPrefix = "awsui-util-status-";
    let statusSuffix;
    switch (status) {
        case SANDBOX_STATUS.STOPPED:
            if(error_msg) {
                statusSuffix = "negative";
            } else {
                statusSuffix = "inactive";
            }
            break;
        case SANDBOX_STATUS.IN_SERVICE:
            statusSuffix = "positive";
            break;
        case SANDBOX_STATUS.FAILED:
            statusSuffix = "negative";
            break;
        default:
            statusSuffix = "info";
            break;
    }

    return statusPrefix + statusSuffix;
}

function getStatusIconName(status: SANDBOX_STATUS, error_msg: string): any {
    const iconPrefix = "status-";
    let iconSuffix;
    switch (status) {
        case SANDBOX_STATUS.STOPPED:
            if(error_msg) {
                iconSuffix = "info";
            } else {
                iconSuffix = "negative";
            }
            break;
        case SANDBOX_STATUS.FAILED:
            iconSuffix = "info";
            break;
        case SANDBOX_STATUS.IN_SERVICE:
            iconSuffix = "positive";
            break;
        default:
            iconSuffix = "in-progress";
    }

    return iconPrefix + iconSuffix;
}

export const COLUMN_DEFINITIONS = [
    {
        id: "name",
        header: () => "Sandbox Name",
        cell: (item: any) => (
            <div>
                <a href={`/update/${item.sandboxId}`}>{item.sandboxId}</a>
            </div>
        ),
        minWidth: "200px",
        allowLineWrap: true,
    },
    {
        id: "status",
        cell: (item: any) => {
            const status = stringToSandboxStatus(item.status);
            const statusLabel = (
                <div className={getStatusClass(status, item.statusReason)}>
                    <Icon name={getStatusIconName(status, item.statusReason)} />
                    <span>{` ${item.status}`}</span>
                </div>
            );
            let additionalInfo;
            if (item.statusReason.includes("ResourceLimitExceeded")) {
                additionalInfo = (
                    <p>
                        Refer to our wiki on how to{" "}
                        <a
                            href={
                                "https://w.amazon.com/bin/view/CMLS/SecureAISandbox/Guides/SandboxInstanceOperations#HIncreaseSageMakerlimits"
                            }
                        >
                            increase SageMaker limits.
                        </a>{" "}
                    </p>
                );
            } else if (item.statusReason.includes("update")) {
                additionalInfo = (
                    <p>
                        Cannot update Sandbox's latest configuration. Please click 'Retry' button if you want to retry with the latest configuration.
                        Please click 'Edit' button if you want to update the current configuration.
                    </p>
                );
            }
            const failedMessage = (
                <>
                    <p>{item.statusReason || "Failed for unknown reason"}</p>
                    {additionalInfo}
                    <p>
                        Please try again or cut a{" "}
                        <a href={"https://sim.amazon.com/issues/create?template=9fe6666d-3453-4bc3-84c1-dbd4cbd8cfc4"}>
                            SIM
                        </a>{" "}
                        for help troubleshooting.
                    </p>
                </>
            );
            if (status === SANDBOX_STATUS.FAILED || item.statusReason) {
                return (
                    <Popover dismissButton={false} content={failedMessage}>
                        {statusLabel}
                    </Popover>
                );
            } else {
                return statusLabel;
            }
        },
        header: () => "Status",
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "type",
        header: () => "Instance Type",
        cell: (item: any) => item.type,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "platform",
        header: () => "Platform Identifier",
        cell: (item: any) => item.platform,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "size",
        header: () => "Size",
        cell: (item: any) => item.size,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "idleTime",
        header: () => "Idle Time",
        cell: (item: any) => item.idleTime,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "idleThreshold",
        header: () => "Idle Threshold",
        cell: (item: any) => item.idleThreshold,
        minWidth: "100px",
        allowLineWrap: true,
    },
    {
        id: "actions",
        header: () => "Actions",
        cell: (item: any) => (
            <div style={{ textAlign: "center" }}>
                {item.status === "InService" ? (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button wrapText={false} onClick={() => item.endpoint(item.sandboxId)}>
                                        JupyterLab
                                    </Button>
                                </td>
                                <td>
                                    <Button wrapText={false} onClick={() => item.endpoint(item.sandboxId, false)}>
                                        Jupyter
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    "-"
                )}
            </div>
        ),
        minWidth: "270px",
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS = [
    { id: "name", field: "sandboxId" },
    { id: "status", field: "status" },
    { id: "type", field: "type" },
    { id: "platform", field: "platform" },
    { id: "size", field: "size" },
    { id: "idleTime", field: "idleTime" },
    { id: "idleThreshold", field: "idleThreshold" },
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: "Main sandbox properties",
        options: [
            { id: "name", label: "Sandbox name", editable: false, visible: true },
            { id: "status", label: "Status", editable: true, visible: true },
            { id: "type", label: "Instance type", editable: true, visible: true },
            { id: "platform", label: "Platform identifier", editable: true, visible: true },
            { id: "size", label: "Size", editable: true, visible: false },
            { id: "idleTime", label: "Idle time", editable: true, visible: true },
            { id: "idleThreshold", label: "Idle threshold", editable: true, visible: false },
            { id: "actions", label: "Actions", editable: false, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: "10 Sandboxes" },
    { value: 30, label: "30 Sandboxes" },
    { value: 50, label: "50 Sandboxes" },
];

export const PROPERTY_FILTERING_OPTIONS = [
    { propertyLabel: "Sandbox name", propertyKey: "sandboxId", groupValuesLabel: "Sandbox name values", values: [] },
    { propertyLabel: "Status", propertyKey: "status", groupValuesLabel: "Status values", values: [] },
    { propertyLabel: "Instance type", propertyKey: "type", groupValuesLabel: "Instance type values", values: [] },
    {
        propertyLabel: "Platform identifier",
        propertyKey: "platform",
        groupValuesLabel: "Platform identifier values",
        values: [],
    },
    { propertyLabel: "Size", propertyKey: "size", groupValuesLabel: "Size values", values: [] },
    { propertyLabel: "Idle time", propertyKey: "idleTime", groupValuesLabel: "Idle time values", values: [] },
    {
        propertyLabel: "Idle threshold",
        propertyKey: "idleThreshold",
        groupValuesLabel: "Idle threshold values",
        values: [],
    },
];

export const CUSTOM_PREFERENCE_OPTIONS = [
    { value: "table", label: "Table" },
    { value: "cards", label: "Cards" },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: "Filter sandboxes by property or value",
    operationAndText: "and",
    operationNotAndText: "and not",
    operationOrText: "or",
    operationNotOrText: "or not",
    clearFiltersText: "Clear filters",
    groupPropertiesText: "Properties",
    groupValuesText: "Values",
    filteringEmpty: "No results found",
    filteringLabel: "Filter sandboxes",
    filteringStatusText: "Loading",
};
