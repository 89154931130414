import {CognitoAuth} from "amazon-cognito-auth-js";

export const LIST_WORKFLOW_TEMPLATES_PATH = "/workflow_templates_cognito"
export const GET_WORKFLOW_TEMPLATES_VERSION_DETAIL_PATH = "/workflow_template_cognito/{gitfarmPackage}/{templateName}/{templateVersion}"
export const LIST_WORKFLOW_EXECUTIONS_PATH = "/workflow_executions_cognito"
export const GET_WORKFLOW_EXECUTION_DETAIL_PATH = "/workflow_execution_cognito/{executionId}"
export const GET_EXECUTION_DOCUMENT_STRUCTURE_PATH = "/execution_document_structure_cognito"
export const REQUEST_CONCRETE_EXECUTION_DOCUMENT_UPLOAD_LINK_PATH = "/concrete_execution_document_cognito"
export const GET_EXECUTION_DOCUMENT_PATH = "/concrete_execution_document_cognito/{concreteExecutionDocumentName}/{concreteExecutionDocumentVersion}"
export const START_WORKFLOW_EXECUTION_PATH = "/workflow_execution_cognito"
export const LIST_REGISTERED_EXECUTION_AWS_ACCOUNT_PATH = "/registered_execution_aws_accounts_cognito"
export const CREATE_SCHEDULE_PATH="/schedule_cognito"
export const LIST_SCHEDULED_WORKFLOWS_PATH="/schedule_triggered_workflows_cognito"
export const LIST_SCHEDULES_PATHS="/schedules_cognito"
export const GET_SCHEDULE_DETAIL_PATH="/schedule_cognito/{scheduleId}"
export const UPDATE_SCHEDULE_PATH="/update_schedule_cognito"

export class MODSWorkflowManagementClient {
    endpoint: string;
    auth: CognitoAuth;
    apigClient: any;

    constructor(endpoint: string, auth: CognitoAuth) {
        this.endpoint = endpoint;
        this.auth = auth;

        const apigClientFactory = require("aws-api-gateway-client").default;
        const config = {
            invokeUrl: endpoint,
            retries: 4,
            retryCondition: (err: any) => {
                return err.response && err.response.status === 500;
            }
        };
        this.apigClient = apigClientFactory.newClient(config);
    }

    getAuthHeaders(pathQueries?: any) {
        const session = this.auth.getSignInUserSession();
        return {
            queryParams: pathQueries,
            headers: {
                Authorization: session.getIdToken().getJwtToken(),
                "Content-Type": "application/x-amz-json-1.1",
            },
        };
    }

    // <---------------------------- Workflow template operation ----------------------------> //

    listWorkflowTemplates(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({}, LIST_WORKFLOW_TEMPLATES_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to list workflow templates: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    getWorkflowTemplateVersionDetail(gitfarmPackage: string, templateName: string, templateVersion: string) {
        const method = "GET";
        const pathParams = {
            gitfarmPackage,
            templateName,
            templateVersion
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi(pathParams, GET_WORKFLOW_TEMPLATES_VERSION_DETAIL_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get workflow template detail: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    // <---------------------------- Workflow execution operation ----------------------------> //

    listWorkflowExecutions(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({}, LIST_WORKFLOW_EXECUTIONS_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to list template executions: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    getWorkflowExecutionDetail(pathQueries: object, executionId: string) {
        const method = "GET";
        const pathParams = {
            executionId
        };
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi(pathParams, GET_WORKFLOW_EXECUTION_DETAIL_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get workflow execution detail: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    startWorkflowExecution(gitfarmPackageName: string,
                           templateName: string,
                           templateVersion: string,
                           executionDocumentName: string,
                           executionDocumentVersion: string,
                           executionAwsAccountId: string,
                           requester: string) {
        const method = "POST";
        const requestBody = {
            gitfarmPackageName: gitfarmPackageName,
            templateName: templateName,
            templateVersion: templateVersion,
            executionDocumentName: executionDocumentName,
            executionDocumentVersion: executionDocumentVersion,
            executionAwsAccountId: executionAwsAccountId,
            requester: requester
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi({}, START_WORKFLOW_EXECUTION_PATH, method, opts,requestBody)
            .catch((err: any) => {
                console.error(`Failed to start workflow execution : ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    listRegisteredExecutionAwsAccounts(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({}, LIST_REGISTERED_EXECUTION_AWS_ACCOUNT_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get aws accounts: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    // <---------------------------- Execution Document operation ----------------------------> //

    getExecutionDocumentStructure(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({},GET_EXECUTION_DOCUMENT_STRUCTURE_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get execution document structure: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    getConcreteExecutionDocument(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({},GET_EXECUTION_DOCUMENT_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get execution document: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    requestConcreteExecutionDocumentUploadLink(gitfarmPackageName: string, templateName: string, templateVersion: string, concreteExecutionDocumentName: string) {
        const method = "POST";
        const requestBody = {
            gitfarmPackageName: gitfarmPackageName,
            templateName: templateName,
            templateVersion: templateVersion,
            concreteExecutionDocumentName: concreteExecutionDocumentName
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi({},REQUEST_CONCRETE_EXECUTION_DOCUMENT_UPLOAD_LINK_PATH, method, opts, requestBody)
            .catch((err: any) => {
                console.error(`Failed to get concrete execution document upload link: ${err.message}`);
                console.error(err);
                return err.response
            });
    }


// <---------------------------- Schedule operation ----------------------------> //

        createSchedule(
            scheduleId: string,
            cronExpression: string,
            scheduleStartDate: string,
            gitfarmPackageName: string,
            templateName: string,
            templateVersion: string,
            executionDocumentName: string,
            executionDocumentVersion: string,
            executionAwsAccountId: string,
            requester: string) {
        const method = "POST";
        const requestBody = {
            scheduleId: scheduleId,
            cronExpression: cronExpression,
            scheduleStartDate: scheduleStartDate,
            gitfarmPackageName: gitfarmPackageName,
            templateName: templateName,
            templateVersion: templateVersion,
            executionDocumentName: executionDocumentName,
            executionDocumentVersion: executionDocumentVersion,
            executionAwsAccountId: executionAwsAccountId,
            requester: requester
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi({}, CREATE_SCHEDULE_PATH, method, opts,requestBody)
            .catch((err: any) => {
                console.error(`Failed to create schedule : ${err.message}`);
                console.log(`Failed to create schedule : ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    listSchedules(pathQueries: object) {
        const method = "GET";
        const opts = this.getAuthHeaders(pathQueries);
        return this.apigClient.invokeApi({}, LIST_SCHEDULES_PATHS, method, opts)
            .catch((err: any) => {
                console.error(`Failed to list schedules: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    getScheduleDetail(scheduleId: string) {
        const method = "GET";
        const pathParams = {
            scheduleId
        };
        const opts = this.getAuthHeaders();
        return this.apigClient.invokeApi(pathParams, GET_SCHEDULE_DETAIL_PATH, method, opts)
            .catch((err: any) => {
                console.error(`Failed to get schedule detail: ${err.message}`);
                console.error(err);
                return err.response
            });
    }

    updateSchedules(
        scheduleId: string,
        ownerTeamId: string,
        cronExpression: string,
        scheduleStatus:string,
        scheduleStartDate: string,
        gitfarmPackageName: string,
        templateName: string,
        templateVersion: string,
        executionDocumentName: string,
        executionDocumentVersion: string,
        executionAwsAccountId: string,
        requester: string) {
    const method = "POST";
    const requestBody = {
        scheduleId: scheduleId,
        ownerTeamId: ownerTeamId,
        cronExpression: cronExpression,
        scheduleStatus:scheduleStatus,
        scheduleStartDate: scheduleStartDate,
        gitfarmPackageName: gitfarmPackageName,
        templateName: templateName,
        templateVersion: templateVersion,
        executionDocumentName: executionDocumentName,
        executionDocumentVersion: executionDocumentVersion,
        executionAwsAccountId: executionAwsAccountId,
        requester: requester
    };
    const opts = this.getAuthHeaders();
    return this.apigClient.invokeApi({}, UPDATE_SCHEDULE_PATH, method, opts,requestBody)
        .catch((err: any) => {
            console.error(`Failed to update schedule : ${err.message}`);
            console.log(`Failed to update schedule : ${err.message}`);
            console.error(err);
            return err.response
        });
}

listScheduledWorkflows(pathQueries: object) {
    const method = "GET";
    const opts = this.getAuthHeaders(pathQueries);

    return this.apigClient.invokeApi({}, LIST_SCHEDULED_WORKFLOWS_PATH, "GET", opts)
        .catch((err: any) => {
            console.error(`Failed to list scheduled workflows: ${err.message}`);
            console.error(err);
            return err.response
        });
}




}
