import React from 'react';
import SideBar from '../../navigation/sidebar.js';
import { AppLayout } from '@amzn/awsui-components-react'; 
import SandboxTable from './table/table.js';
import AppContext from "../../context/AppContext";
import {getSidebarItemFromPageStage} from "../home/home";

class Sandboxes extends React.Component {
    static contextType = AppContext;
    render() {
        return (
            <AppLayout
                content={<SandboxTable />}
                navigation={<SideBar activeHref="/sandboxes" items={getSidebarItemFromPageStage(this.context.pageStage)}/>}
                navigationOpen={true}
                toolsHide={true}
            />
        );
    }
}

export default Sandboxes;
